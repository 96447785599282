//packages
import { getNames } from 'country-list'
import { useFormik } from 'formik'
import languageNameMap from 'language-name-map/map'
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { toast } from 'react-toastify'
import * as yup from 'yup'

//custom components
import CardFooter from './CardFooter'
import DetailPanel from './DetailPanel'
import JobInterestsPanel from './JobInterestsPanel'
import PortfolioPanel from './PortfolioPanel'
import SkillsAndExperiencePanel from './SkillsAndExperiencePanel'
import DeleteConfirmModal from 'views/employer/setting/components/DeleteConfirmModal'

//redux & api
import { getUser, updateUser } from 'app/store/profileSlice'
import CardHeader from './CardHeader'
import _ from 'lodash'

const validationSchema = {
  Details: yup.object().shape({
    name: yup.string().required('You must enter your name'),
    language: yup
      .array()
      .of(yup.object())
      .min(1, 'You must enter Language You Speak')
      .required('You must enter Language needed'),
    country: yup.object().required('You must enter your country'),
    // website: yup
    //   .string()
    //   .matches(
    //     /((https?):\/\/)?(www.)?[a-z0-9]+(\.[a-z]{2,}){1,3}(#?\/?[a-zA-Z0-9#]+)*\/?(\?[a-zA-Z0-9-_]+=[a-zA-Z0-9-%]+&?)?$/,
    //     'Enter correct url!'
    //   )
    //   .required('Please enter website'),
    bio: yup.string().required('You must enter bio'),
  }),
  'Skills & Experience': yup.object().shape({
    job_title: yup.string().required('You must enter your Job Title'),
    num_experience: yup
      .number()
      .typeError('Please input number only')
      .required('You must enter your years of experience'),
    skills: yup.array().min(1).required('You must enter your skills'),
  }),
}

export default function CardBody({ selectedTab }) {
  const [showDeleteConfirmModal, setShowDeleteConfirmModal] = useState(false)
  const [initialValues, setInitialValues] = React.useState({
    //details
    avatar: '',
    video: '',
    name: '',
    language: [],
    country: '',
    // website: '',
    bio: '',
    // birthday: null,
    //skills & Experience
    job_title: '',
    num_experience: '',
    skills: [],
    resume: '',
    //job interests
    hourly_rate: '',
    annual_rate: '',
    isHide: false,
  })

  const dispatch = useDispatch()
  const profile = useSelector(getUser)

  const formik = useFormik({
    initialValues,
    validationSchema: validationSchema[selectedTab],
    onSubmit: (values) => {
      handleSubmit(values)
    },
    enableReinitialize: true,
  })

  useEffect(() => {
    if (profile) {
      console.log('debug: profile', profile)
      const params = {
        //details
        avatar: profile.avatar,
        video: profile.video,
        name: profile.name,
        email: profile.email,
        language: _.isArray(profile.language)
          ? profile.language.map((sV) => {
              return {
                id: sV,
                label: sV,
                name: sV,
              }
            })
          : [],
        country: profile.country
          ? { id: profile.country, name: profile.country }
          : null,
        website: profile.website,
        bio: profile.bio,
        // birthday: profile.birthday,
        //skills & Experience
        job_title: profile.job_title,
        num_experience: profile.num_experience,
        skills: profile.skills.map((v) => {
          return {
            id: v,
            label: v,
            name: v,
          }
        }),
        resume: profile.resume,
        //job interests
        hourly_rate: profile.hourly_rate,
        annual_rate: profile.annual_rate,
        isHide: profile.isHide,
      }
      setInitialValues(params)
    }
  }, [profile])

  const handleSubmit = async (values) => {
    let data = {}
    formik.setSubmitting(true)
    if (selectedTab === 'Details') {
      data = {
        avatar: values.avatar,
        video: values.video,
        name: values.name,
        language: values.language.map((v) => v.id),
        country: values.country.name,
        website: values.website,
        bio: values.bio,
        // birthday: values.birthday,
      }
    }
    if (selectedTab === 'Skills & Experience') {
      data = {
        avatar: values.avatar,
        job_title: values.job_title,
        num_experience: values.num_experience,
        skills: values.skills.map((v) => v.id),
        resume: values.resume,
      }
    }
    if (selectedTab === 'Pay Preferences') {
      data = {
        avatar: values.avatar,
        hourly_rate: values.hourly_rate,
        annual_rate: values.annual_rate,
        isHide: values.isHide,
      }
    }
    const params = {
      userId: profile.id,
      data,
    }

    const resultAction = await dispatch(updateUser(params))
    if (updateUser.fulfilled.match(resultAction)) {
      toast.success('Profile Updated Successfully')
      formik.resetForm()
    } else {
      if (resultAction.payload) {
        toast.error('Update User failed: ' + resultAction.payload.errorMessage)
      } else {
        toast.error('Update User failed' + resultAction.error.message)
      }
    }
    formik.setSubmitting(false)
  }

  const openDeleteConfirmModal = async () => {
    setShowDeleteConfirmModal(true)
  }

  return (
    <div className={`${selectedTab === "Skills & Experience" ? "mx-[15px] sm:mx-[0px]" : ""}`}>
      <CardHeader formik={formik} />
      <div className="bg-white shadow-[14px_41px_100px_rgba(49,89,211,0.15)] rounded-3xl py-[30px] md:py-10 px-[25px] md:px-9">
        <div>
          {selectedTab === 'Details' && <DetailPanel formik={formik} />}
          {selectedTab === 'Skills & Experience' && (
            <SkillsAndExperiencePanel formik={formik} />
          )}
          {selectedTab === 'Pay Preferences' && (
            <JobInterestsPanel formik={formik} />
          )}
          {selectedTab === 'Portfolio' && <PortfolioPanel />}
          {selectedTab !== 'Portfolio' && (
            <CardFooter
              deleteProfile={openDeleteConfirmModal}
              formik={formik}
            />
          )}
        </div>
      </div>
      <DeleteConfirmModal
        isOpen={showDeleteConfirmModal}
        closeModal={() => setShowDeleteConfirmModal(false)}
        profile={profile}
      />
    </div>
  )
}
