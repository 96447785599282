import React, { useRef, useState, useEffect } from 'react'

export default function IntroductoryVideo({ videoSource }) {
  const videoRef = useRef(null)
  const [isPlaying, setIsPlaying] = useState(false)

  const handlePlayClick = () => {
    if (videoRef.current) {
      if (isPlaying) {
        videoRef.current.pause()
      } else {
        videoRef.current.play()
      }
      setIsPlaying(!isPlaying)
    }
  }

  const handleVideoEnded = () => {
    setIsPlaying(false) // Show the SVGs when the video ends
    videoRef.current.src = videoRef.current.src // Reset the video source to trigger a refresh
  }

  useEffect(() => {
    // Listen for the 'ended' event to detect when the video playback is complete
    videoRef.current.addEventListener('ended', handleVideoEnded)

    // Clean up the event listener when the component unmounts
    return () => {
      if (videoRef.current) {
        videoRef.current.removeEventListener('ended', handleVideoEnded)
      }
    }
  }, [])

  return (
    <section className="md:pt-24 2xl:pt-36 3xl:pt-8 4xl:pt-32">
      <div className="z-0">
        <h2 className="text-[#11142D] font-semibold leading-[30px] md:leading-18 text-[20px] md:text-5xl text-center">
          Let's Change The World
        </h2>
      </div>
      <div className="pt-[23px] md:pt-12 mx-[15px] md:mx-0 relative">
        <video
          ref={videoRef}
          src={videoSource}
          preload="metadata"
          controls={false}
          type="video/mp4"
          poster="https://remote-recruit-website-assets.s3.amazonaws.com/rrvideoplaceholder.png"
          className="w-790 h-444 flex-shrink-0 rounded-[40.112px] shadow-[15.17757px 44.4486px 108.41122px 0px rgba(49, 89, 211, 0.06)]"
        ></video>
        {!isPlaying && (
          <button
            onClick={handlePlayClick}
            className="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 translate-y-2 w-16 h-16 rounded-full bg-red-500 text-white"
          >
            <div className="flex items-center justify-center">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="80"
                height="80"
                viewBox="0 0 80 80"
                fill="none"
                className="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2"
              >
                <circle cx="40" cy="40" r="40" fill="white" />
              </svg>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="26"
                height="30"
                viewBox="0 0 26 30"
                fill="none"
                className="z-10"
              >
                <path
                  d="M26 15L0.5 29.7224L0.5 0.277568L26 15Z"
                  fill="#52B4DA"
                />
              </svg>
            </div>
          </button>
        )}
      </div>
    </section>
  )
}
