//packages
import clsx from 'clsx'
import AuthCard from 'components/Card/AuthCard'
import TextField from 'components/Input/TextField'
import { useFormik } from 'formik'
import React, { useState } from 'react'
import { Link, useNavigate } from 'react-router-dom'
import { toast } from 'react-toastify'
import * as yup from 'yup'

//redux & apis
import { checkEmailExists } from 'app/services/auth.service'
import { AnimateSpinIcon } from 'components/Icons'

import { getCharacterValidationError } from 'app/utils/function'
import Checkbox from 'components/Checkbox/Checkbox'

const schema = yup.object().shape({
  name: yup.string(),
  email: yup
    .string()
    .email('You must enter a valid email')
    .required('You must enter a email'),
  password: yup
    .string()
    .required('Please enter your password.')
    .min(8, 'Password must have at least 8 characters')
    .matches(/[0-9]/, getCharacterValidationError('digit'))
    .matches(/[a-z]/, getCharacterValidationError('lowercase'))
    .matches(/[A-Z]/, getCharacterValidationError('uppercase')),
  confirmPassword: yup
    .string()
    .label('confirm password')
    .required()
    .oneOf([yup.ref('password'), null], 'Passwords must match'),
  role: yup.string().label('Role').required('Role is required'),
})

const SignUpDialog = () => {
  const navigate = useNavigate()

  const formik = useFormik({
    initialValues: {
      email: '',
      name: '',
      password: '',
      confirmPassword: '',
      role: 'employer',
      termsAgreed: false,
    },
    validationSchema: schema,
    onSubmit: async (values) => {
      try {
        formik.setSubmitting(true)
        const params = {
          email: values.email,
        }
        const result = await checkEmailExists(params)
        if (result.data.existence) {
          toast.error('This email has been already registered!')
        } else {
          if (formik.values.role === 'contractor') {
            navigate('/auth/contractor/register', {
              state: values,
            })
          } else {
            navigate('/auth/employee/register', {
              state: values,
            })
          }
        }
      } catch (err) {
        toast.error('Network Error')
      } finally {
        formik.setSubmitting(false)
      }
    },
  })

  return (
    <AuthCard className="mt-10">
      <h2 className="text-black-100 text-[24px] md:text-m4xl font-semibold leading-[36px] md:leading-[60px]">
        Sign Up
      </h2>

      <form onSubmit={formik.handleSubmit}>
        <div className="mt-[10px] md:mt-5 text-gray-300 leading-4 text-[12px] md:text-xs">
          Existing User?{' '}
          <Link to="/auth" className="text-[#0049C6] ml-4">
            Sign In
          </Link>
        </div>

        <div className="mt-[30px] md:mt-10 space-y-[10px] md:space-y-6">
          <div className="font-medium leading-4 text-[12px] md:text-xs text-gray-300">
            Please select your path
          </div>
          <div className="grid grid-cols-2 gap-x-4">
            <div
              onClick={() => formik.setFieldValue('role', 'employer')}
              className={clsx(
                'cursor-pointer hover:border-blue-100 hover:text-black-100 font-semibold text-sm bg-transparent border-[2px] border-solid rounded-2xl text-center py-[18px]',
                formik.values.role === 'employer'
                  ? 'border-blue-100 text-black-100'
                  : 'text-gray-100 border-gray-400'
              )}
            >
              Employer
            </div>
            <div
              onClick={() => formik.setFieldValue('role', 'contractor')}
              className={clsx(
                'cursor-pointer hover:border-blue-100 hover:text-black-100 font-semibold text-sm bg-transparent border-[2px] border-solid rounded-2xl text-center py-[18px]',
                formik.values.role === 'contractor'
                  ? 'border-blue-100 text-black-100'
                  : 'text-gray-100 border-gray-400'
              )}
            >
              Job Seeker
            </div>
          </div>
          {formik.touched['role'] && formik.errors['role'] && (
            <div
              className={clsx(
                'text-red-300 text-[12px] md:text-xs font-semibold'
              )}
              style={{ marginTop: '6px' }}
            >
              {formik.errors['role']}
            </div>
          )}
        </div>
        <div className="mt-[20px] md:mt-10 space-y-[20px] md:space-y-[24px]">
          {formik.values.role === 'contractor' && (
            <div className="space-y-[20px] md:space-y-[24px]">
              <TextField
                label="Your Full Name"
                name="name"
                id="name"
                onChange={formik.handleChange}
                error={formik.touched['name'] ? formik.errors['name'] : ''}
              />
            </div>
          )}

          <TextField
            label="Email"
            name="email"
            id="email"
            error={formik.touched['email'] ? formik.errors['email'] : ''}
            onChange={formik.handleChange}
          />
          
          <div className="grid grid-cols-2 gap-x-4">
            <TextField
              label="Password"
              type="password"
              name="password"
              id="password"
              error={
                formik.touched['password'] ? formik.errors['password'] : ''
              }
              onChange={formik.handleChange}
            />
            <TextField
              label="Confirm"
              type="password"
              name="confirmPassword"
              id="confirmPassword"
              error={
                formik.touched['confirmPassword']
                  ? formik.errors['confirmPassword']
                  : ''
              }
              onChange={formik.handleChange}
            />
          </div>
        </div>
        <div className="text-black-100 opacity-80 text-[12px] md:text-xs leading-[16px] mt-[12px] md:mt-4">
          Password must be a minimum of eight characters and contain at least
          one uppercase and one lowercase letter.
        </div>

        {/* Check for the terms of service */}
        <div className="flex items-center mt-6">
          <Checkbox
            value={formik.values.termsAgreed}
            onChange={(e) =>
              formik.setFieldValue('termsAgreed', e.target.checked)
            }
            variant="primary"
            className=""
          />
          <span className="text-[#11142D] font-inter text-xs">
            I agree to the{' '}
            <Link className="text-[#52B4DA] underline" to="/terms-of-service">
              terms of service
            </Link>
          </span>
        </div>

        <div className="mt-[25px] md:mt-10">
          <button
            disabled={formik.isSubmitting || !formik.values.termsAgreed}
            type="submit"
            className="flex justify-center bg-blue-100 hover:opacity-80 rounded-2xl font-bold text-sm text-white w-full text-center py-[18px] disabled:opacity-50"
          >
            Sign Up
            {formik.isSubmitting && (
              <AnimateSpinIcon className="w-6 h-6 ml-2" />
            )}
          </button>
        </div>
      </form>
    </AuthCard>
  )
}

export default SignUpDialog
