//packages
import React, { useCallback, useEffect, useState } from 'react'
import { ChatEngine, getChats } from 'react-chat-engine'
import { useSelector } from 'react-redux'

//custom components
import ChatDetailPanel from './components/ChatDetailPanel'
import Sidebar from './components/Sidebar'

//redux & api
import { getUser } from 'app/store/profileSlice'
import useQuery from 'hooks/useQuery'
import { MessageDownloadIcon } from 'components/Icons'
import { updateUserLastActivity } from 'app/services/auth.service'

export default function Messages() {
  const [selectedChat, setSelectedChat] = useState(null)
  const [messages, setMessages] = useState([])
  const [isConnected, setIsConnected] = useState(false)
  const [chats, setChats] = useState([])
  const [creds, setCreds] = useState(null)
  const [loadedMessages, setLoadedMessages] = useState(false)
  console.log(chats)
  console.log(selectedChat)

  const profile = useSelector(getUser)
  const query = useQuery()
  // console.log(profile)

  useEffect(async () => {
    //Update user last activity
    if (profile) {
      await updateUserLastActivity(profile.id)
    }
  }, [chats])

  useEffect(() => {
    if (query?.chatId && !selectedChat) {
      let chat = _.find(chats, (v) => v.id.toString() === query.chatId)
      setSelectedChat(chat)
    }
  }, [chats, query])

  const fetchChats = useCallback(() => {
    getChats(creds, (data) => {
      setChats(data)
      setLoadedMessages(true)
    })
  }, [creds])

  useEffect(() => {
    if (creds) {
      fetchChats()
    }
  }, [creds])

  useEffect(() => {
    if (chats.length > 0) {
      setSelectedChat(chats[0])
    }
  }, [chats])

  const handleNewMessage = (chatId, message) => {
    if (selectedChat && selectedChat.id == chatId) {
      // setMessages([...messages, message])
      fetchChats()
    }
  }

  const handleDeleteChat = (chat) => {
    setChats((prev) => prev.filter((c) => c.id !== chat.id))
    if (selectedChat.id == chat.id) {
      setSelectedChat(null)
    }
  }

  const handleNewChat = (chat) => {
    if (chat.is_authenticated) {
      setChats((prev) => [chat, ...prev])
    }
  }

  return (
    <div
      className="h-full min-h-screen px-[15px] md:pl-16 pb-[70px] md:pb-0"
      id="message-chat"
    >
      <div className="py-[30px] md:py-12 flex-1 md:border-r relative md:border-r-gray-200 border-solid border-y-0 border-l-0 pr-11">
        <div className="text-black-100">
          <h5 className="leading-[21px] md:leading-8 font-medium text-[14px] md:text-2xl">
            Welcome,{' '}
            {profile?.firstName
              ? profile?.firstName + '!'
              : profile?.companyName}
          </h5>
          <h2 className="leading-[36px] md:leading-18 font-semibold text-[24px] md:text-5xl">
            Your Messages
          </h2>
        </div>
      </div>
      {loadedMessages && chats.length === 0 ? (
        <div className="mr-12 bg-white py-24 mt-15">
          <div className="flex justify-center">
            <div className="w-60 h-60 bg-gray-1400 flex items-center justify-center rounded-full">
              <MessageDownloadIcon />
            </div>
          </div>
          <div className="text-[#878995] text-2xl font-medium text-center mt-12">
            No messages found.
          </div>
        </div>
      ) : (
        <div>
          {profile && (
            <ChatEngine
              height="100%"
              userName={profile.id}
              className="bg-transparent"
              userSecret={profile.id}
              onConnect={(creds) => {
                setCreds(creds)
                setIsConnected(true)
              }}
              projectID={process.env.REACT_APP_CHAT_ENGINE_PROJECT_ID}
              renderChatList={(chatAppState) => (
                <Sidebar
                  setSelectedChat={setSelectedChat}
                  selectedChatId={selectedChat?.id}
                  connected={isConnected}
                  chats={chats}
                  setChats={setChats}
                  profile={profile}
                  creds={creds}
                />
              )}
              renderChatSettings={(chatAppState) => null}
              renderChatFeed={(chatAppState) => (
                <ChatDetailPanel
                  chats={chats}
                  connected={isConnected}
                  messages={messages}
                  setMessages={setMessages}
                  selectedChat={selectedChat}
                />
              )}
              onNewChat={(chat) => handleNewChat(chat)}
              onNewMessage={(chatId, message) =>
                handleNewMessage(chatId, message)
              }
              onDeleteChat={(chat) => handleDeleteChat(chat)}
            />
          )}
        </div>
      )}
    </div>
  )
}
