import React, { useCallback, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Navigate, useParams } from 'react-router-dom'

//custom components
import JobDescriptionPanel from './components/JobDescriptionPanel'
import JobDetailPanel from './components/JobDetailPanel'
import SubmitApplicationModal from './components/SubmitApplicationModal'
import TopHeader from './components/TopHeader'
import { BackCircleIcon, EliminationDarkCircleIcon } from 'components/Icons'
import { useNavigate, useLocation } from 'react-router-dom'

//redux & apis
import { getHomePageJobWithID } from 'app/store/jobSlice'
import Header from 'views/NewHomePage/components/Header'
import IntroductionText from 'views/NewHomePage/components/IntroductionText'
import Footer from 'views/NewHomePage/components/Footer'
// import { getUser } from 'app/store/profileSlice'

export default function JobDetailNonAuth() {
  let [isOpen, setIsOpen] = useState(false)

  const params = useParams()
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const location = useLocation()

  const job = useSelector((state) => state.job.searchedJob)
  // console.log(job)

  const fetchJob = useCallback(() => {
    dispatch(getHomePageJobWithID({ id: params?.id }))
  }, [])

  useEffect(() => {
    fetchJob()
  }, [])

  function closeModal() {
    setIsOpen(false)
  }

  const handleSubmitApplicationModal = useCallback(() => {
    const redirectUrl =
      location.pathname.replace(/^\/home\/job/, '/contractor/jobs') +
      location.search
    localStorage.setItem('redirectTo', redirectUrl)

    navigate(`/auth?redirect=${encodeURIComponent(redirectUrl)}`)
  })

  const submittedSuccess = () => {
    closeModal()
    fetchJob()
  }

  const goBack = () => {
    navigate(-1)
  }

  const ScrollToTop = () => {
    const { pathname } = useLocation()
    useEffect(() => {
      // "document.documentElement.scrollTo" is the magic for React Router Dom v6
      document.documentElement.scrollTo({
        top: 0,
        left: 0,
        behavior: 'smooth',
      })
    }, [pathname])
    return null
  }

  // if (!user) return null

  return (
    <div className="bg-[#1E3E85] min-h-[100vh]">
      <Header />
      {/* <IntroductionText /> */}
      <div className="min-h-screen px-[15px] md:px-16 pb-14 mt-6">
        {ScrollToTop()}
        {/* <div className="py-[30px] md:py-12 flex-1 relative pr-11">
        <div className="text-black-100 space-y-[2px]">
          <h5
            onClick={goBack}
            className="leading-[21px] md:leading-8 font-medium text-[14px] md:text-2xl text-black-100 flex space-x-[18px] items-center cursor-pointer"
          >
            <BackCircleIcon />
            <h5 className="text-[#52B4DA] text-[24px] font-medium">Back</h5>
          </h5>
          <h2 className="leading-[36px] md:leading-18 font-semibold text-[24px] md:text-5xl">
            Let's Find Work
          </h2>
        </div>
      </div> */}
        {job && (
          <div className="">
            <div>
              <TopHeader
                job={job}
                handleSubmitApplicationModal={handleSubmitApplicationModal}
                submittedSuccess={submittedSuccess}
              />
            </div>

            {/* if job was deactivated */}
            {job?.status !== 'active' && (
              <div className="flex bg-[#f1e4e8] mt-16 p-5 rounded-2xl gap-3">
                <EliminationDarkCircleIcon />
                <p className="mb-0 font-bold text-[14px] text-red-200">
                  This job has been deactivated!
                </p>
              </div>
            )}

            <div className="md:flex mt-[15px] md:mt-16 md:space-x-15 space-y-[15px] md:space-y-0">
              <div className="w-full md:w-[375px]">
                <JobDetailPanel
                  job={job}
                  handleSubmitApplicationModal={handleSubmitApplicationModal}
                />
              </div>
              <div className="w-full md:flex-1">
                <JobDescriptionPanel description={job.description} />
              </div>
            </div>
          </div>
        )}
        <SubmitApplicationModal
          isOpen={isOpen}
          closeModal={closeModal}
          submittedSuccess={submittedSuccess}
        />
      </div>
      <Footer />
    </div>
  )
}
