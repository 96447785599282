import React from 'react'

//assets
import Header from 'views/home/components/Header'
import Footer from 'views/home/components/Footer'
import PremiumRRLogo from 'assets/images/premium-icon-card.png'

const PrivacyPolicy = () => {
  return (
    <>
      <div className="h-96 w-full mobile-auth-bg md:bg-auth-bg bg-no-repeat bg-top sm:bg-[length:100%_38vh] md:bg-[length:100%_48vh] relative z-50">
        <Header active="Home" />
        <div className="flex flex-col items-center justify-center">
          <p className="text-white text-4xl font-bold">Privacy Policy</p>
          <p className="text-white text-sm font-medium opacity-80">
            Effective date: July 30, 2023
          </p>
        </div>
        <div className="hidden sm:block absolute top-58 lg:left-44 xl:left-44 md:left-0 sm:left-0 w-60 flex-shrink-0 rounded-2xl bg-white shadow-box-shadow-custom">
          <p className="p-5">1. Introduction</p>
          <p className="p-5">2. Definition</p>
          <p className="p-5">3. Use of the Platform by Minors</p>
          <p className="p-5">4. Types of Information We Collect</p>
          <p className="p-5">5. How We Collect Information</p>
          <p className="p-5">6. Why We Collect Your Information</p>
          <p className="p-5">7. How We Share or Disclose Your Information.</p>
          <p className="p-5">8. How Long Your Information Is Kept</p>
          <p className="p-5">9. Third Party Websites and Links</p>
          <p className="p-5">10. Your Data Rights</p>
          <p className="p-5">11. California Privacy Rights</p>
          <p className="p-5">12. Data Security</p>
        </div>
      </div>
      <div className="sm:grid sm:grid-cols-3 gap-4 z-20">
        <div className="hidden sm:block col-span-1 w-60 h-max flex-shrink-0 rounded-3xl bg-gray-200 shadow-box-shadow z-20"></div>
        <div className="col-span-2 sm:w-5/6 sm:px-0 p-12">
          <section>
            <p className="text-[#11142D] font-poppins text-lg font-medium">
              1. Introduction
            </p>
            <div className="text-[#6D6E7A] font-poppins text-base font-normal leading-7 mt-4">
              <p>
                Remote Recruit, Inc. ("<b>Remote Recruit</b>,” “<b>us</b>,” or “
                <b>we</b>”) is a company organized under the laws of the State
                of [Virginia/Florida]. We provide a Platform that connects Job
                Seekers and Employers. We respect your privacy and are committed
                to protecting it by complying with this Privacy Policy.
                <br /> <br /> This Privacy Policy, together with our Terms of
                Use, details the types of information we may collect about you
                or other Users when you access our Platform. This Privacy Policy
                also sets forth our practices with respect to collecting, using,
                maintaining, protecting, and disclosing such information.
                <br /> <br /> This Privacy Policy applies to any information we
                may collect: (A) on the Platform; (B) in email, text, and other
                electronic messages between you and us (whether through the
                Platform or otherwise); [(C) through mobile and desktop
                applications you download from the Platform, which provide
                dedicated, non-browser-based interactions between you and the
                Platform; and (D) when you interact with our advertising and
                applications on third party websites, if those applications or
                advertising include links to this policy].
                <br /> <br /> By accessing or using the Platform, you agree that
                you have carefully read this Privacy Policy, understand our
                policies and practices regarding your information, and consent
                to this Privacy Policy. If you do not agree with this Privacy
                Policy, then you may not access or use the Platform.
                <br /> <br /> We may revise this Privacy Policy from time to
                time in our sole discretion. [We will notify you in advance of
                material changes to these Terms by posting the revised terms on
                the Platform and/or by emailing you.] All revised terms of the
                Privacy Policy are effective when we publish the revised terms
                on the webpage, [or the effective date as stated in the notice,
                if applicable]. Your continued use of the Platform following the
                effective date of the revised Privacy Policy means that you
                accept and agree to the modified Privacy Policy. If you do not
                agree to any of the provisions contained in this Privacy Policy,
                then you may not access or use (or continue to access or use)
                the Platform.
              </p>
            </div>
          </section>
          <section className="mt-10">
            <p className="text-[#11142D] font-poppins text-lg font-medium">
              2. Definitions
            </p>
            <div className="text-[#6D6E7A] font-poppins text-base font-normal leading-7 mt-4">
              <p>
                Any capitalized terms without definition have the meanings
                ascribed to them in our Terms of Use.
                <br />
                <br />
                <ul>
                  <li>
                    <b>“Personal Data”</b> means information that identifies,
                    relates to, describes, references, is reasonably capable of
                    being associated with, or could reasonably be linked,
                    directly or indirectly, with a particular consumer,
                    household, or device. The types of Personal Data that we
                    collect is detailed in Section 4 below.
                  </li>
                </ul>
              </p>
            </div>
          </section>
          <section className="mt-10">
            <p className="text-[#11142D] font-poppins text-lg font-medium">
              3. Use of the Platform by Minors.
            </p>
            <div className="text-[#6D6E7A] font-poppins text-base font-normal leading-7 mt-4">
              <p>
                As set forth in the Terms of Use, all Users must be at least 18
                years of age or the age of majority in the state in which they
                reside (those who do not meet these requirements are “
                <b>Minors</b>
                ”). Minors may not provide any Personal Data to or on the
                Platform. We do not knowingly collect Personal Data from any
                User who is a Minor. If you are a Minor, you are strictly
                prohibited from using the Platform, providing any information to
                us or on the Platform, including your name, address, telephone
                number, email address, or Platform username. If we determine
                that we have collected or received a Minor’s Personal Data, we
                will delete such Personal Data. If you believe that we may have
                any information, including Personal Data, that belongs to a
                Minor, please contact us at [contact information].
              </p>
            </div>
          </section>
          <section className="mt-10">
            <p className="text-[#11142D] font-poppins text-lg font-medium">
              4. Types of Information We Collect
            </p>
            <div className="text-[#6D6E7A] font-poppins text-base font-normal leading-7 mt-4">
              <p>
                We collect the following categories of Personal Data from Users:
              </p>
              <table className="mt-4">
                <tr className="border-b border-dotted border-gray-500">
                  <th className="border-b border-gray-800">Category</th>
                  <th className="border-b border-gray-800">Examples</th>
                </tr>
                <tr className="border-b border-dotted border-gray-500">
                  <td className="align-top">Personal Identifiers</td>
                  <td>
                    Personal Identifiers A real name, alias, postal address,
                    unique personal identifier, online identifier, Internet
                    Protocol address, email address, account name, Social
                    Security number, driver's license number, passport number,
                    or other similar identifiers
                  </td>
                </tr>
                <tr className="border-b border-dotted border-gray-500">
                  <td className="align-top">
                    California Customer Records Personal Data categories
                  </td>
                  <td>
                    A name, signature, Social Security number, physical
                    characteristics or description, address, telephone number,
                    passport number, driver's license or state identification
                    card number, insurance policy number, education, employment,
                    employment history, bank account number, credit card number,
                    debit card number, or any other financial information,
                    medical information, or health insurance information. Some
                    personal information included in this category may overlap
                    with other categories.
                  </td>
                </tr>
                <tr className="border-b border-dotted border-gray-500">
                  <td className="align-top">
                    Protected Classification Characteristics
                  </td>
                  <td>
                    Age, race, color, ancestry, national origin, citizenship,
                    religion or creed, marital status, medical condition,
                    physical or mental disability, sex (including gender, gender
                    identity, gender expression, pregnancy or childbirth and
                    related medical conditions), sexual orientation, veteran or
                    military status, genetic information (including familial
                    genetic information)
                  </td>
                </tr>
                <tr className="border-b border-dotted border-gray-500">
                  <td className="align-top">
                    Commercial/Financial Information
                  </td>
                  <td>
                    Insurance information, bank account number, credit card
                    number, debit card number, or any other financial
                    information, records of personal property, products or
                    services purchased, obtained, or considered, or other
                    purchasing or consuming histories or tendencies
                  </td>
                </tr>
                <tr className="border-b border-dotted border-gray-500">
                  <td className="align-top">
                    Biometric and Health Information
                  </td>
                  <td>
                    Genetic, physiological, behavioral, and biological
                    characteristics, or activity patterns used to extract a
                    template or other identifier or identifying information,
                    such as, fingerprints, faceprints, and voiceprints, iris or
                    retina scans, keystroke, gait, or other physical patterns,
                    and sleep, health, exercise data, medical information, or
                    physical characteristics or description
                  </td>
                </tr>
                <tr className="border-b border-dotted border-gray-500">
                  <td className="align-top">Internet or network activity</td>
                  <td>
                    Browsing history, search history, information on a
                    consumer's interaction with a website, application, or
                    advertisement
                  </td>
                </tr>
                <tr className="border-b border-dotted border-gray-500">
                  <td className="align-top">Geolocation data</td>
                  <td>Physical location or movements</td>
                </tr>
                <tr className="border-b border-dotted border-gray-500">
                  <td className="align-top">Sensory data</td>
                  <td>
                    Audio, electronic, visual, thermal, olfactory, or similar
                    information.
                  </td>
                </tr>
                <tr className="border-b border-dotted border-gray-500">
                  <td className="align-top">
                    Non-public education information
                  </td>
                  <td>
                    Education records directly related to a student maintained
                    by an educational institution or party acting on its behalf,
                    such as grades, transcripts, class lists, student schedules,
                    student identification codes, student financial information,
                    or student disciplinary records.
                  </td>
                </tr>
                <tr>
                  <td className="align-top">
                    Inferences drawn from other personal information
                  </td>
                  <td>
                    Profile reflecting a person's preferences, characteristics,
                    psychological trends, predispositions, behavior, attitudes,
                    intelligence, abilities, and aptitudes.
                  </td>
                </tr>
              </table>
            </div>
          </section>
          <section className="mt-10">
            <p className="text-[#11142D] font-poppins text-lg font-medium">
              5. How We Collect Information
            </p>
            <div className="text-[#6D6E7A] font-poppins text-base font-normal leading-7 mt-4">
              <p>
                We collect the above categories of Personal Data from the
                sources listed below:
              </p>
              <ul className="list-disc ml-8 mt-4">
                <li>
                  <b>Information You Provide to Us</b>. You may provide us with
                  Personal Data voluntarily, including: (i) information you
                  provide when registering for an account to use or access the
                  Platform; (ii) when you post User Content; (iii) contact
                  information and correspondence if you contact us; and (iv)
                  details of transactions if you subscribe to certain paid
                  services on the Platform. [Add any additional ways that a User
                  provides Personal Data].
                </li>
                <li>
                  <b>Information from Third Parties</b>. We may collect Personal
                  Data about you from certain third parties, including [social
                  media websites, data providers, email or other marketing
                  partners, advertisers, or website analytics providers]
                </li>
                <li>
                  <b>Automatic Data Collection</b>.
                  <ul className="list-disc ml-8">
                    <li>
                      When you use the Platform, we may use automatic data
                      collection technologies to collect certain information
                      about your equipment, browsing behavior, and patterns,
                      including: (i) details of your visit to the Platform,
                      including traffic data, location data, logs, etc.; and
                      (ii) information about your computer and internet
                      connection, including your IP address, operating system,
                      and browser type.
                    </li>
                    <li>
                      We may also use the above technologies to collect
                      information about your online activity over time and
                      across third party website or other online services. [You
                      may find information about how you can opt out of this
                      behavioral tracking here.]
                    </li>
                  </ul>
                </li>
                <li>
                  <b>Cookies and Tracking Technology</b>. Automatic data
                  collection may also include the following cookies:
                  <ul className="list-disc ml-8">
                    <li>
                      Strictly necessary cookies: These are cookies that are
                      required for the operation of the Platform (i.e., cookies
                      that enable you to log into secure areas of the Platform,
                      purchase services, if applicable, etc.).
                    </li>
                    <li>
                      Analytical/performance cookies: These are cookies that
                      allow us to recognize and count the number of Users and
                      gives us visibility into how Users are using the Platform.
                      These cookies help us improve the way the Platform works.
                    </li>
                    <li>
                      Functionality cookies: These cookies are used to recognize
                      you when you return to the Platform and allows us to
                      personalize the content to you (e.g., recognizing the
                      choice of language or region).
                    </li>
                    <li>
                      Targeting cookies: These cookies record your visit to the
                      Platform, the pages you have visited, and the links you
                      have followed. We use this information to make the
                      Platform and advertising displayed on it more relevant to
                      your interests. We may also share this information with
                      third parties for this purpose.
                    </li>
                  </ul>
                </li>
              </ul>
            </div>
          </section>
          <section className="mt-10">
            <p className="text-[#11142D] font-poppins text-lg font-medium">
              6. Why We Collect Your Information
            </p>
            <div className="text-[#6D6E7A] font-poppins text-base font-normal leading-7 mt-4">
              <ul className="list-disc ml-8">
                <li>
                  <b>General Purpose</b>. We may use, sell, or disclose the
                  Personal Data we collect for one or more of the following
                  purposes: (i) to fulfill the reason you provided the
                  information (e.g., you provide your contact information to
                  contact us about products or services); (ii) to provide,
                  support, personalize, and develop the Platform; (iii) to
                  create, maintain, customize, and secure your account with us;
                  (iv) to process your requests, purchases, transactions,
                  payments, and prevent transactional fraud; (v) to provide you
                  with support and respond to your inquiries; (vi) to
                  personalize your experience using the Platform and deliver
                  content relevant to your interests, including targeted
                  advertisements; or (vii) to help maintain the safety,
                  security, and integrity of the Platform. [Add any additional
                  purposes]
                </li>
              </ul>
            </div>
          </section>
          <section className="mt-10">
            <p className="text-[#11142D] font-poppins text-lg font-medium">
              7. How We Share or Disclose Your Information
            </p>
            <div className="text-[#6D6E7A] font-poppins text-base font-normal leading-7 mt-4">
              <ul className="list-disc ml-8">
                <li>
                  <b>Information that is Not Personal Data</b>. We may share,
                  sell, or disclose aggregated information about Users and
                  information that does not identify any individual, without
                  restriction.
                </li>
                <li>
                  <b>Personal Data</b>. We may disclose your Personal Data to a
                  third party for a business purpose or we may sell your
                  Personal Data, subject to your right to opt out of those
                  sales, as set forth in Section 10 below. When we disclose
                  Personal Data for business purposes, we enter into a
                  contractual agreement that describes the purpose and requires
                  the recipient to keep that Personal Data confidential and only
                  use it for its performance under the contract.
                </li>
                <li>
                  We may share your Personal Data with: (i) service providers;
                  and (ii) data aggregators. [Any additional parties with whom
                  you share Personal Data]. We may also disclose your Personal
                  Data to comply with a court order, law, or legal process,
                  including in response to a government or regulatory request;
                  to enforce our Terms of Use; or if we believe disclosure is
                  necessary or appropriate to protect the rights, property, or
                  safety of Remote Recruit, our customers, or other third
                  parties.
                </li>
              </ul>
            </div>
          </section>
          <section className="mt-10">
            <p className="text-[#11142D] font-poppins text-lg font-medium">
              8. How Long Your Information Is Kept
            </p>
            <div className="text-[#6D6E7A] font-poppins text-base font-normal leading-7 mt-4">
              <p>
                We only retain your Personal Data for as long as necessary to
                fulfill the purposes for which we collect the Personal Data,
                including to satisfy any legal, accounting, or reporting
                requirements. To determine the appropriate retention period for
                Personal Data, we consider the amount, nature, and sensitivity
                of the Personal Data, the potential risk of harm from
                unauthorized use or disclosure of the Personal Data, and the
                purpose for which we process your Personal Data (and whether we
                can achieve those purposes through other means), and the
                applicable legal requirements.
              </p>
            </div>
          </section>
          <section className="mt-10">
            <p className="text-[#11142D] font-poppins text-lg font-medium">
              9. Third Party Websites and Links
            </p>
            <div className="text-[#6D6E7A] font-poppins text-base font-normal leading-7 mt-4">
              <p>
                Some content on the Platform, including advertisements, are
                served by third parties, including advertisers, ad networks and
                servers, content providers, and application providers. These
                third parties may use cookies to collect information about you
                when you use the Platform. The information they collect may be
                associated with your Personal Data or they may collect
                information about your online activities over time and across
                different websites and other online services. They may use this
                information to provide you with behavioral advertising or other
                targeted content. <br />
                <br />
                We do not control these third parties, their tracking
                technologies, or how they may be used. If you have any questions
                about any advertisements or other targeted contact, you should
                contact that third party directly.
              </p>
            </div>
          </section>
          <section className="mt-10">
            <p className="text-[#11142D] font-poppins text-lg font-medium">
              10. Your Data Rights
            </p>
            <div className="text-[#6D6E7A] font-poppins text-base font-normal leading-7 mt-4">
              <ul className="list-disc ml-8">
                <li>
                  <b>Rights Generally</b>. You have the right to:
                  <ul className="list-disc ml-8">
                    <li>
                      request access to, or correct or delete any Personal Data
                      that you have provided to us. Notwithstanding the
                      foregoing, we may not accommodate a request to change
                      information if we believe such change would violate any
                      law or legal requirement or cause the information to be
                      incorrect. You may send any such requests to [email or
                      other address].
                    </li>
                    <li>
                      review and change your Personal Data by logging into the
                      Platform and visiting your account profile;{' '}
                    </li>
                    <li>
                      remove or reject cookies from the Platform with your
                      browser settings. However, if you remove or reject
                      cookies, it may impact the Platform’s performance for you;{' '}
                    </li>
                    <li>
                      withdraw your consent, if we have collected or processed
                      your Personal Data with your consent; or{' '}
                    </li>
                    <li>
                      make a complaint to the data privacy authority in your
                      jurisdiction.
                    </li>
                  </ul>
                </li>
                <li>
                  <b>Personal Data Sales Opt-Out</b>. You have the right to
                  direct us not to sell your personal information (the “right to
                  opt out”). To exercise your right to opt out, you (or your
                  authorized representative) may submit a request by visiting
                  [hyperlink to URL with the title “Do Not Sell My Personal
                  Information”]. Once you make an opt out request, we will wait
                  at least twelve (12) months before asking you to reauthorize
                  personal information sales. However, you may change your mind
                  and opt back in to personal information sales at any time by
                  [opt in instructions or URL link]. You do not need to create
                  an account with us to exercise your opt-out rights. We will
                  only use personal information provided in an opt-out request
                  to review and comply with the request.
                </li>
              </ul>
            </div>
          </section>
          <section className="mt-10">
            <p className="text-[#11142D] font-poppins text-lg font-medium">
              11. California Privacy Rights
            </p>
            <div className="text-[#6D6E7A] font-poppins text-base font-normal leading-7 mt-4">
              <p>
                California’s “Shine the Light” law (Civil Code Section §
                1798.83) permits Users that are California residents to request
                certain information regarding our disclosure of personal
                information to third parties for their direct marketing
                purposes. To make such a request, please contact us at [contact
                information].
              </p>
            </div>
          </section>
          <section className="mt-10">
            <p className="text-[#11142D] font-poppins text-lg font-medium">
              12. Data Security
            </p>
            <div className="text-[#6D6E7A] font-poppins text-base font-normal leading-7 mt-4">
              <p>
                We have implemented measures to secure your Personal Data from
                accidental loss and unauthorized access, use, alteration, and
                disclosure. [All information you provide to us is stored on our
                secure servers behind firewalls.] The safety and security of
                your information also depends on you. As detailed in the Terms,
                you are responsible for keeping your login credentials
                confidential and not sharing it with anyone.
                <br />
                <br /> Unfortunately, the transmission of information via the
                Internet is not completely secure. Although we do our best to
                protect your Personal Data, we cannot guarantee the security of
                your Personal Data used on the Platform. Any transmission of
                Personal Data on the Platform is done at your own risk.
              </p>
            </div>
          </section>
        </div>
      </div>
      <section className="pt-[50px] md:pt-36">
        <h2 className="text-black-100 font-semibold leading-[30px] md:leading-18 text-[20px] md:text-5xl text-center">
          Help Is One Click Away
        </h2>
        <div className="relative p-5 md:space-x-10 z-10 md:flex max-w-[345px] md:max-w-[860px] mx-auto bg-white shadow-[14px_41px_100px_rgba(49,89,211,0.12)] rounded-[20px] md:rounded-[49px]">
          <div className="mx-auto w-28 md:w-60 md:h-60 flex-none">
            <img
              className="w-full h-1 md:w-60 md:h-60 object-cover"
              src={PremiumRRLogo}
              alt="premium-card"
            />
            <div className="flex flex-col justify-center items-center h-0">
              {/* <p className="flex flex-col justify-center items-center w-40 h-10 flex-shrink-0 text-center font-poppins text-2xl font-semibold leading-[36px] tracking-[0.2px] bg-gradient-to-br from-[#52B4DA] via-transparent to-[#1E3E85] bg-clip-text text-transparent">
                Premium
              </p>
              <p class="text-[rgba(17, 20, 45, 0.50)] font-poppins text-center text-[1.125rem] font-normal leading-[2.1875rem] flex w-[12.4375rem] h-[2rem] flex-col items-center justify-center flex-shrink-0">
                Additional Cost
              </p> */}
            </div>
          </div>
          <div className="flex items-center justify-center mt-[30px] md:mt-0">
            <div className="space-y-5">
              <h3 className="text-black-100 leading-9 text-[18px] md:text-xl font-medium">
                Find Out Which Plan is Right for You
              </h3>
              <p className="text-base text-[rgba(17,20,45,0.8)] leading-8">
                For businesses that want unlimited access to candidates and the
                ability to post multiple jobs at once, check out the premium
                plan. But feel free to dip your toe in the basic plan first if
                you want--no fees, no paywall.
              </p>
            </div>
          </div>
          <div className="absolute w-full px-[10px] md:px-[35px] -z-10 left-[-35px] -bottom-4 md:-bottom-6">
            <div className="shadow-[14px_41px_100px_rgba(49,89,211,0.12)] rounded-[20px] md:rounded-[49px] h-[100px] bg-white opacity-50"></div>
          </div>
        </div>
      </section>

      <div className="-mt-32">
        <Footer className="z-0 pt-[160px] sm:pt-[200px] xl:pt-64 2xl:pt-80" />
      </div>
    </>
  )
}

export default PrivacyPolicy
