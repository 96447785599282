//packages
import clsx from 'clsx'
import _ from 'lodash'
import React, { useCallback, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'

//custom components
import FilterBar from './components/FilterBar'
import Results from './components/Results'
import SearchFilter from './components/SearchFilter'
import FilterBarModal from './components/FilterBarModal'
import Dropdown from 'components/dropdown/Dropdown'

//redux
// import { fetchCandidates } from 'app/store/candidateSlice'
import { getUser } from 'app/store/profileSlice'
import {
  getJobsCount,
  getJobsList,
  searchJobsByFilter,
} from 'app/store/jobSlice'

//consts
import { PAGINATION_LIST } from 'app/utils/list'
import { updateUserLastActivity } from 'app/services/auth.service'

export default function FindJobs() {
  // this is for filters that are saved
  const languageStored =
    JSON.parse(localStorage.getItem('filter_jobs_language')) ?? []
  const locationsStored =
    JSON.parse(localStorage.getItem('filter_jobs_location')) ?? []
  const durationsStored =
    JSON.parse(localStorage.getItem('filter_jobs_duration')) ?? []
  const minHourlyRateStored =
    localStorage.getItem('filter_jobs_minHourlyRate') ?? ''
  const minAnnualRateStored =
    localStorage.getItem('filter_jobs_minAnnualRate') ?? ''
  const keywordsStored =
    JSON.parse(localStorage.getItem('filter_jobs_keywords')) ?? []
  const fj_page = localStorage.getItem('fj_page')
    ? Number(localStorage.getItem('fj_page'))
    : ''

  const [filter, setFilter] = useState((prev) => {
    let tempParam = {}

    // languages
    if (languageStored.length !== 0) {
      tempParam['languages'] = languageStored.map((v) => v.id)
    }

    // locations
    if (locationsStored.length !== 0) {
      tempParam['countries'] = locationsStored.map((v) => v.id)
    }

    // durations
    if (durationsStored.length !== 0) {
      tempParam['duration'] = durationsStored.map((v) => v.id)
    }

    // min hourly
    if (minHourlyRateStored !== '') {
      tempParam['hourly'] =
        minHourlyRateStored.length >= 5
          ? Number(minHourlyRateStored.replace(/,/g, ''))
          : Number(minHourlyRateStored)
    }

    // min annual
    if (minAnnualRateStored !== '') {
      tempParam['annual'] =
        minAnnualRateStored.length >= 5
          ? Number(minAnnualRateStored.replace(/,/g, ''))
          : Number(minAnnualRateStored)
    }

    // keywords
    if (keywordsStored.length !== 0) {
      tempParam['skills'] = keywordsStored.map((v) => v)
    }

    return tempParam
  })
  const [showFilterBarModal, setShowFilterBarModal] = useState(false)
  const [loading, setLoading] = useState(false)
  const [numberPerPage, setNumberPerPage] = useState(PAGINATION_LIST[0])
  const [currentPage, setCurrentPage] = useState(fj_page !== '' ? fj_page : 1)

  const dispatch = useDispatch()
  const jobs = useSelector(getJobsList)
  const user = useSelector(getUser)
  const count = useSelector(getJobsCount)

  useEffect(async () => {
    //Update user last activity
    if (user) {
      await updateUserLastActivity(user.id)
    }
  }, [])

  const fetchList = useCallback(async () => {
    if (user?.id) {
      try {
        setLoading(true)
        let param = {
          offset: (currentPage - 1) * numberPerPage.name,
          limit: numberPerPage.name,
          userId: user.id,
        }
        if (!_.isEmpty(filter)) {
          param = { ...param, filter }
        }
        await dispatch(searchJobsByFilter(param))
        setLoading(false)
      } catch (err) {
        console.log(err)
      } finally {
        setLoading(false)
      }
    }
  }, [filter, user, currentPage])

  useEffect(() => {
    fetchList()
    const elementTop = document.getElementById('findjobs-top')
    elementTop?.scrollIntoView({ behavior: 'smooth' })
  }, [fetchList, currentPage])

  useEffect(() => {
    if (fj_page === '') {
      setCurrentPage(1)
    } else {
      setCurrentPage(fj_page)
    }
  }, [numberPerPage])

  const onShowFilterModal = () => {
    setShowFilterBarModal(true)
  }

  const onHandleReset = () => {
    setFilter(null)
  }

  const onPageChange = (param) => {
    setCurrentPage(param)
    localStorage.setItem('fj_page', param)
  }

  console.log('debug: jobs', jobs)

  if (!user) return null

  return (
    <div className="md:flex min-h-screen px-[15px] md:px-16" id="findjobs-top">
      <div className="py-[30px] md:py-12 flex-1 md:border-r relative border-r-gray-200 border-solid border-y-0 border-l-0 md:pr-11">
        <div className="text-black-100">
          <h5 className="text-black-100 leading-[21px] md:leading-8 font-medium text-[14px] md:text-2xl">
            Welcome, {user.name}!
          </h5>
          <h2 className="leading-[36px] md:leading-[72px] font-semibold text-[24px] md:text-5xl">
            Let's Find Jobs
          </h2>
        </div>
        <div className="mt-[20px] md:mt-0">
          <SearchFilter
            filter={filter}
            setFilter={setFilter}
            onShowFilterModal={onShowFilterModal}
          />
        </div>
        <div className="mt-9">
          <div className="space-y-6">
            <div className="flex justify-between items-center">
              <div className="text-sm font-semibold text-[#808191]">
                <span className="text-[#1E3E85] font-bold text-sm">
                  {count}
                </span>{' '}
                jobs found
              </div>
              <div className="flex items-center space-x-3">
                <div className="text-[#808191] font-semibold text-sm">View</div>
                <div className="w-[70px]">
                  <Dropdown
                    data={PAGINATION_LIST}
                    label=""
                    value={numberPerPage}
                    onChange={(data) => {
                      setNumberPerPage(data)
                    }}
                    className="bg-white bg-[rgba(228,228,228,0.25)] h-[50px] md:h-[58px]"
                    parentClassName="w-full"
                    labelClassName="text-[#1E3E85] font-semibold text-sm"
                  />
                </div>
                <div className="text-[#808191] text-sm font-semibold">
                  jobs per page
                </div>
              </div>
            </div>
            <Results
              jobs={jobs}
              count={count}
              loading={loading}
              currentPage={currentPage}
              onPageChange={onPageChange}
              pageSize={numberPerPage.name}
            />
          </div>
        </div>
      </div>
      <div className="hidden md:block md:w-[398px]">
        <FilterBar
          filter={filter}
          setFilter={setFilter}
          setCurrentPage={setCurrentPage}
        />
      </div>
      {showFilterBarModal && (
        <FilterBarModal
          isOpen={showFilterBarModal}
          closeModal={() => setShowFilterBarModal(false)}
          filter={filter}
          setFilter={setFilter}
          setCurrentPage={setCurrentPage}
          onHandleReset={onHandleReset}
        />
      )}
    </div>
  )
}
