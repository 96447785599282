//packages
import React from 'react'
import Carousel from 'react-multi-carousel'
import clsx from 'clsx'

//custom components
import { CircleArrowIcon } from 'components/Icons'
import JobCard from './JobCard'

const responsive = {
  superLargeDesktop: {
    // the naming can be any, depends on you.
    breakpoint: { max: 4000, min: 3000 },
    items: 5,
  },
  desktop: {
    breakpoint: { max: 3000, min: 1024 },
    items: 2,
  },
  tablet: {
    breakpoint: { max: 1024, min: 768 },
    items: 2,
  },
  mobile: {
    breakpoint: { max: 768, min: 0 },
    items: 1,
  },
}

const CustomButtonGroup = ({ next, previous, carouselState }) => {
  const { totalItems, currentSlide } = carouselState
  return (
    <div className="absolute w-full justify-between hidden md:flex -top-16 z-10">
      <div onClick={() => previous()}>
        <CircleArrowIcon className="cursor-pointer" />
      </div>
      <div onClick={() => next()}>
        <CircleArrowIcon className="rotate-180 cursor-pointer" />
      </div>
    </div>
  )
}

const CustomDot = ({ onClick, ...rest }) => {
  const {
    active,
    carouselState: { currentSlide, deviceType },
  } = rest
  return (
    <button
      className={clsx(
        active ? 'bg-[#1E3E85] w-[28px]' : 'w-[16px] bg-[#E0E0E4]',
        'rounded-lg h-[3px]'
      )}
      onClick={() => onClick()}
    />
  )
}

export default function RecentJobPostings({ jobs }) {
  return (
    <section className="pt-[50px] md:pt-36">
      <div className="z-0">
        <h2 className="text-black-100 font-semibold leading-[30px] md:leading-18 text-[20px] md:text-5xl text-center">
          Recent Job Postings
        </h2>
        <h6 className="text-gray-100 text-[14px] md:text-lg text-center max-w-[724px] mx-auto mt-[12px] md:mt-5 px-[15px] md:px-0">
          Check out a couple of the most recent jobs posted here. These are top
          companies ready to make verified payments--you just have to reach out
          below!
        </h6>
      </div>
      <div className="pt-[48px] md:pt-24 relative px-[15px] md:px-0">
        <Carousel
          showDots
          customDot={<CustomDot />}
          arrows={false}
          responsive={responsive}
          customButtonGroup={<CustomButtonGroup />}
          // dotListClass="space-x-[8px] top-[30px] flex md:hidden"
          dotListClass="space-x-[8px] bottom-[-10px] flex md:hidden"
          draggable
          swipeable
        >
          {jobs.map((job) => (
            <JobCard job={job} key={job.id} />
          ))}
        </Carousel>
      </div>
    </section>
  )
}
