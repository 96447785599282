//packages
import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Popover } from '@headlessui/react'
import { Link, useNavigate } from 'react-router-dom'

//custom components
import { ChevronIcon, ProfileIcon, LogoutIcon } from 'components/Icons'

//redux & api
import { getUser } from 'app/store/profileSlice'
import { LogOut } from 'app/store/authSlice'
import { SettingIcon } from 'components/Icons'
import { logout } from 'app/services/user.service'

const Avatar = require('assets/images/avatar.png')

export default function ProfileDropdown() {
  const profile = useSelector(getUser)
  const dispatch = useDispatch()
  const navigate = useNavigate()

  const menus = [
    {
      name: 'My Profile',
      route: `/${profile?.type}/profile`,
      icon: ProfileIcon,
    },
    { name: 'Settings', route: `/${profile?.type}/setting`, icon: SettingIcon },
  ]

  const logoutFunc = () => {
    const user = JSON.parse(localStorage.getItem('user'))
    if (user) {
      //Delete the session
      logout({ token: user.token })
    }

    dispatch(LogOut())
    localStorage.clear()
    navigate('/auth')
  }

  if (!profile) return null

  console.log('debug: profile', profile)

  return (
    <Popover className="relative">
      <Popover.Button className="text-left flex justify-start w-full focus-within:outline-none">
        <div className="flex items-center justify-between cursor-pointer px-5 w-full ">
          <div className="flex space-x-4">
            <div className="w-[40px] md:w-10 h-[40px] md:h-10">
              <img
                className="w-full h-full rounded-full object-cover"
                src={profile.avatar ? profile.avatar : Avatar}
                alt="avatar"
              />
            </div>
            <div className="flex items-center">
              <div className="space-y-[2px]">
                {profile.type === 'contractor' ? (
                  <div className="text-white text-[14px] md:text-sm font-semibold truncate max-w-[96px]">
                    {profile.name}
                  </div>
                ) : (
                  <div className="text-white text-[14px] md:text-sm font-semibold truncate max-w-[96px]">
                    {profile.emType === 'individual'
                      ? `${profile.firstName} ${profile.lastName}`
                      : profile.companyName}
                  </div>
                )}

                {profile.type === 'employer' && (
                  <div className="text-[rgba(225,227,242,0.8)] text-[13px] md:text-sm capitalize">
                    {profile.plan}
                  </div>
                )}
              </div>
            </div>
          </div>
          <div className="space-y-[6px]">
            <ChevronIcon className="rotate-180 text-[rgba(255,255,255,0.2)]" />
            <ChevronIcon />
          </div>
        </div>
      </Popover.Button>
      <Popover.Panel className="left-0 absolute bottom-14 z-10 w-full">
        <div className="grid bg-white py-3 px-5 rounded-[10px] shadow-[14px_10px_20px_rgba(49,89,211,0.15)]">
          {menus.map((v) => (
            <Link
              className="text-[#1E3E85] leading-8 font-semibold font-inter flex items-center"
              key={v.name}
              to={v.route}
            >
              <v.icon className="text-[#1E3E85] mr-3" size={13} />
              {v.name}
            </Link>
          ))}
          <div
            onClick={logoutFunc}
            className="text-[#1E3E85] leading-8 font-semibold font-inter flex items-center cursor-pointer"
          >
            <LogoutIcon className="text-[#1E3E85] mr-3" size={13} />
            Log out
          </div>
        </div>
        <img src="/solutions.jpg" alt="" />
      </Popover.Panel>
    </Popover>
  )
}
