import React from 'react'

export default function ArrowRight({ size = 138, className }) {
  return (
    <svg
      className={className}
      width={size}
      viewBox="0 0 138 138"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g id="Arrow Button">
        <circle
          id="Ellipse 1"
          cx="69"
          cy="69"
          r="69"
          fill="url(#paint0_linear_80_1065)"
        />
        <g id="Combined Shape" filter="url(#filter0_d_80_1065)">
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M81.5547 46.8393C80.1623 45.5615 78.0015 45.512 76.5487 46.7871L76.2561 47.0731C74.9783 48.4655 74.9288 50.6263 76.2038 52.0791L87.7648 65.2502H36L35.6166 65.2696C33.7256 65.4616 32.25 67.0586 32.25 69.0002C32.25 71.0713 33.9289 72.7502 36 72.7502H87.7829L76.1993 85.9989L75.9469 86.321C74.8634 87.8694 75.0987 90.018 76.5539 91.2903C78.113 92.6536 80.4821 92.4948 81.8453 90.9357L98.855 71.4824C98.8637 71.4725 98.8724 71.4625 98.8811 71.4525L98.9535 71.3697C100.142 70.0108 100.14 67.9821 98.9491 66.6254L98.8758 66.5418C98.8676 66.5324 98.8593 66.523 98.851 66.5136L81.8408 47.1319L81.5547 46.8393Z"
            fill="white"
          />
        </g>
      </g>
      <defs>
        <filter
          id="filter0_d_80_1065"
          x="26.25"
          y="45.8555"
          width="79.5938"
          height="58.3594"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dy="2" />
          <feGaussianBlur stdDeviation="1" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.182535 0"
          />
          <feBlend
            mode="normal"
            in2="BackgroundImageFix"
            result="effect1_dropShadow_80_1065"
          />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="effect1_dropShadow_80_1065"
            result="shape"
          />
        </filter>
        <linearGradient
          id="paint0_linear_80_1065"
          x1="0"
          y1="0"
          x2="0"
          y2="138"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#78C7E6" />
          <stop offset="1" stopColor="#418DBE" />
        </linearGradient>
      </defs>
    </svg>
  )
}
