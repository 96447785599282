import React from 'react'

export default function FacebookIcon({
  size = 22,
  className = 'text-[#DFE1F2]',
}) {
  return (
    <svg
      width={size}
      viewBox="0 0 22 22"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M7.96483 18.3346V12.624H6.3335V9.36064H7.96483V7.74664C7.96483 5.49397 9.79083 3.66797 12.0442 3.66797H14.4915V6.9313H12.8602C11.9582 6.9313 11.2282 7.6613 11.2282 8.56264V9.36064H14.4915L13.6755 12.624H11.2282V18.3346H7.96483Z"
        fill="currentColor"
      />
    </svg>
  )
}
