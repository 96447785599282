import React from 'react'

export default function CircleArrowIcon({ className = '', size = 124 }) {
  return (
    <svg
      width={size}
      viewBox="0 0 124 124"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <g filter="url(#filter0_d_80_1004)">
        <rect x="16" y="20" width="64" height="64" rx="32" fill="white" />
        <rect
          x="16.5"
          y="20.5"
          width="63"
          height="63"
          rx="31.5"
          stroke="#F6F4FF"
        />
      </g>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M52.3095 59.2012C52.8897 59.783 52.8897 60.7272 52.3095 61.309C51.7277 61.8892 50.7835 61.8892 50.2017 61.309L41.6907 52.798C41.1106 52.2162 41.1106 51.2721 41.6907 50.6902L50.2017 42.1793C50.7835 41.5991 51.7277 41.5991 52.3095 42.1793C52.8897 42.7611 52.8897 43.7052 52.3095 44.287L44.8475 51.7474L52.3095 59.2012Z"
        fill="#808191"
      />
      <defs>
        <filter
          id="filter0_d_80_1004"
          x="0"
          y="0"
          width="124"
          height="124"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dx="14" dy="10" />
          <feGaussianBlur stdDeviation="15" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 0.191202 0 0 0 0 0.347754 0 0 0 0 0.829152 0 0 0 0.12 0"
          />
          <feBlend
            mode="normal"
            in2="BackgroundImageFix"
            result="effect1_dropShadow_80_1004"
          />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="effect1_dropShadow_80_1004"
            result="shape"
          />
        </filter>
      </defs>
    </svg>
  )
}
