import React from 'react'

//assets
import Header from 'views/home/components/Header'
import Footer from 'views/home/components/Footer'
import PremiumRRLogo from 'assets/images/premium-icon-card.png'

const TermsOfService = () => {
  return (
    <>
      <div className="h-46 w-full mobile-auth-bg md:bg-auth-bg bg-no-repeat bg-top sm:bg-[length:100%_38vh] md:bg-[length:100%_48vh] relative z-50">
        <Header active="Home" />
        <div className="flex flex-col items-center justify-center">
          <p className="text-white text-4xl font-bold">Terms of Service</p>
          <p className="text-white text-sm font-medium opacity-80">
            
          </p>
        </div>
      </div>
      <div className="block max-w-[1280px] mx-auto p-5 z-20">
        <div className="hidden sm:block col-span-1 w-60 h-max flex-shrink-0 rounded-3xl bg-gray-200 shadow-box-shadow z-20"></div>
        <div className="col-span-2 w-full">
          
        </div>
      </div>
      <section className="pt-[50px] md:pt-36">
        <h2 className="text-black-100 font-semibold leading-[30px] md:leading-18 text-[20px] md:text-5xl text-center">
          Help Is One Click Away
        </h2>
        <div className="relative p-5 md:space-x-10 z-10 md:flex max-w-[345px] md:max-w-[860px] mx-auto bg-white shadow-[14px_41px_100px_rgba(49,89,211,0.12)] rounded-[20px] md:rounded-[49px]">
          <div className="mx-auto w-28 md:w-60 md:h-60 flex-none">
            <img
              className="w-full h-1 md:w-60 md:h-60 object-cover"
              src={PremiumRRLogo}
              alt="premium-card"
            />
            <div className="flex flex-col justify-center items-center h-0">
              {/* <p className="flex flex-col justify-center items-center w-40 h-10 flex-shrink-0 text-center font-poppins text-2xl font-semibold leading-[36px] tracking-[0.2px] bg-gradient-to-br from-[#52B4DA] via-transparent to-[#1E3E85] bg-clip-text text-transparent">
                Premium
              </p>
              <p class="text-[rgba(17, 20, 45, 0.50)] font-poppins text-center text-[1.125rem] font-normal leading-[2.1875rem] flex w-[12.4375rem] h-[2rem] flex-col items-center justify-center flex-shrink-0">
                Additional Cost
              </p> */}
            </div>
          </div>
          <div className="flex items-center justify-center mt-[30px] md:mt-0">
            <div className="space-y-5">
              <h3 className="text-black-100 leading-9 text-[18px] md:text-xl font-medium">
                Find Out Which Plan is Right for You
              </h3>
              <p className="text-base text-[rgba(17,20,45,0.8)] leading-8">
                For businesses that want unlimited access to candidates and the
                ability to post multiple jobs at once, check out the premium
                plan. But feel free to dip your toe in the basic plan first if
                you want--no fees, no paywall.
              </p>
            </div>
          </div>
          <div className="absolute w-full px-[10px] md:px-[35px] -z-10 left-[-35px] -bottom-4 md:-bottom-6">
            <div className="shadow-[14px_41px_100px_rgba(49,89,211,0.12)] rounded-[20px] md:rounded-[49px] h-[100px] bg-white opacity-50"></div>
          </div>
        </div>
      </section>

      <div className="-mt-32">
        <Footer className="z-0 pt-[160px] sm:pt-[200px] xl:pt-64 2xl:pt-80" />
      </div>
    </>
  )
}

export default TermsOfService
