//packages
import React, { useState } from 'react'
import { useDispatch } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import moment from 'moment'
import _ from 'lodash'
import clsx from 'clsx'
import ShareOnSocial from 'react-share-on-social'

//custom components
import Avatar from 'components/Avatar'
import Button from 'components/Button'
import {
  SponsorIcon,
  TickSquareIcon,
  HandGivingIcon,
  ShareIcon,
} from 'components/Icons'
import RLogo from 'assets/images/rr-logo.png'

//custom functions
import { kFormatter } from 'app/utils/function'

//redux && store
import { setSelectedJob } from 'app/store/jobSlice'
import { removeExtraSpaces } from 'app/utils/sanitizeText'

export default function JobItem({ data, id }) {
  // console.log('dataaaaaa', data)
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const shareUrl =
    process.env.REACT_APP_LIVE_BASE_URL + '/contractor/jobs/' + data.id

  const [openSharePostModal, setOpenSharePostModal] = useState(false)

  const handleClick = () => {
    dispatch(setSelectedJob(data))
    navigate(`/contractor/jobs/${data.id}`)
    localStorage.setItem('fj_selected', id)
  }

  // check the pay preference use in a job item
  const payPreference = (key) => {
    if (data.pay_preference.length > 0) {
      if (data.pay_preference.indexOf(key) > -1) {
        return true
      } else {
        return false
      }
    } else {
      return false
    }
  }

  // check the pay preference mode use in a job item
  const payPreferenceMode = (key) => {
    if (data.pay_preference_mode.length > 0) {
      if (data.pay_preference_mode.indexOf(key) > -1) {
        return true
      } else {
        return false
      }
    } else {
      return false
    }
  }

  console.log('debug: data', data)

  if (!data) return

  const isPremium = data.createdBy?.plan === 'premium'

  const sanitizedDescription = removeExtraSpaces(data?.description)

  return (
    <div className="relative">
      <div
        className={clsx(
          isPremium
            ? 'bg-[#F2F9FB] border-[#D2EBF5] border-solid rounded-xl'
            : 'last:border-b-0 border-b-[1px] border-b-[#E4E4E4] border-solid border-x-0 border-t-0 ',
          'relative'
        )}
        onClick={handleClick}
        id={id}
      >
        {isPremium && (
          <div className="absolute top-0 right-0 text-white font-semibold font-inter py-[2px] pr-[13px] pl-[29px] bg-[#52B4DA] rounded-tr-xl rounded-bl-xl">
            <SponsorIcon className="text-white absolute top-[0px] right-[80px] md:right-[105px]" />
            Sponsored
          </div>
        )}
        {data.applied && (
          <div className="absolute flex items-center top-8 right-5 space-x-1">
            <span className="hidden md:block text-green-100 leading-[12px] font-bold text-[10px]">
              Applied
            </span>
            <TickSquareIcon className="w-[16px] h-[16px] text-green-100" />
          </div>
        )}
        <div className="bg-[rgba(225,227,242,0.2)] hover:bg-blue-100 rounded-xl group px-[20px] md:px-7 py-[20px] md:py-8">
          <div className="flex space-x-7.5 justify-between">
            <div className="bg-white shadow-[0px_4px_8px_rgba(225,227,242,0.32)] rounded-lg w-[40px] md:w-12 h-[40px] md:h-12 flex-none ">
              <Avatar
                image={
                  data.createdBy?.emType === 'company'
                    ? data.createdBy?.logo
                    : data.createdBy?.avatar
                }
                variant="circle"
                className="w-full h-full"
              />
            </div>
            <div className="hidden md:block md:space-y-4 w-3/5">
              <div className="flex items-center space-x-[10px]">
                <div className="text-sm leading-4 text-blue-100 font-semibold group-hover:text-[#FFCE73]">
                  {data.createdBy?.emType === 'individual'
                    ? `${data.createdBy?.firstName} ${data.createdBy?.lastName}`
                    : `${data.createdBy?.companyName}`}
                </div>
                <div className="w-[2px] h-[2px] bg-[#73747A]" />
                <div className="text-gray-1200 leading-4 text-[11px] font-semibold">
                  {data.duration}
                </div>
                {/* <div className="w-[2px] h-[2px] bg-[#73747A]" />
                <div className="text-gray-1200 leading-4 text-[11px] font-semibold">
                  {data.language}
                </div> */}
                <div className="w-[2px] h-[2px] bg-[#73747A]" />
                <div className="text-gray-1200 leading-4 text-[11px] font-semibold">
                  {moment(data.createdAt).fromNow()}
                </div>
              </div>
              <div className="text-black-100 text-[16px] leading-[19px] font-semibold group-hover:text-white">
                {data.title}
              </div>
              <div className="text-gray-100 text-sm leading-6 font-normal group-hover:text-white">
                <div
                  class="content"
                  dangerouslySetInnerHTML={{
                    __html: sanitizedDescription.substring(0, 200),
                  }}
                />{' '}
                {sanitizedDescription.length > 200 && (
                  <span className="font-bold text-blue-300 cursor-pointer">
                    Read More
                  </span>
                )}
              </div>
              <div>
                <Button
                  className="py-[8px] px-[17px] rounded-xl mt-[48px] group-hover:bg-white group-hover:text-blue-100"
                  variant="primary"
                >
                  view job details
                </Button>
              </div>
            </div>
            <div className="hidden md:block md:w-[110px] mt-8 w-2/5">
              <div className="space-y-2">
                {payPreference('hourly') && (
                  <div className="rounded-xl border border-solid border-gray-500 bg-[#F7F8FC] py-[12px]">
                    <div className="text-green-100 leading-6 text-[20px] font-bold text-center">
                      {payPreferenceMode('hourlyRange') ? (
                        <div>
                          ${kFormatter(data.min_hourly_rate)} -{' '}
                          {kFormatter(data.max_hourly_rate)}
                        </div>
                      ) : (
                        <div>${kFormatter(data.exact_hourly_rate)}</div>
                      )}
                    </div>
                    <div className="text-gray-100 text-xs leading-4 font-medium text-center">
                      per hour
                    </div>
                  </div>
                )}
                {payPreference('annual') && (
                  <div className="rounded-xl border border-solid border-gray-500 bg-[#F7F8FC] py-[12px]">
                    <div className="text-green-100 leading-6 text-[20px] font-bold text-center">
                      {payPreferenceMode('annualRange') ? (
                        <div>
                          ${kFormatter(data.min_annual_salary)} -{' '}
                          {kFormatter(data.max_annual_salary)}
                        </div>
                      ) : (
                        <div>${kFormatter(data.exact_annual_salary)}</div>
                      )}
                    </div>
                    <div className="text-gray-100 text-xs leading-4 font-medium text-center">
                      Annual
                    </div>
                  </div>
                )}
              </div>
            </div>
            <div className="h-full flex md:hidden flex-col justify-between space-y-[4px]">
              <div className="text-[14px] leading-[18px] text-blue-100 font-inter font-semibold group-hover:text-white">
                {data.createdBy?.emType === 'individual'
                  ? `${data.createdBy?.firstName} ${data.createdBy?.lastName}`
                  : `${data.createdBy?.companyName}`}
              </div>
              <div className="flex items-center space-x-[5px]">
                {data?.pay_preference &&
                  _.isArray(data?.pay_preference) &&
                  data.pay_preference.map((v) => (
                    <div className="flex items-center space-x-[5px]">
                      <div className="uppercase font-inter leading-[18px] text-green-100 text-[11px] font-bold">
                        {v}
                      </div>
                      <div className="w-[2px] h-[2px] bg-[#73747A]" />
                    </div>
                  ))}
                <div className="text-gray-1200 leading-4 text-[11px] font-semibold">
                  {data.duration}
                </div>
              </div>
            </div>
          </div>
          <div className="block md:hidden mt-[10px]">
            <div className="flex items-center space-x-[10px]">
              <div className="text-gray-1200 leading-[18px] text-[11px] font-semibold font-inter">
                {data.language}
              </div>
            </div>
            <div className="mt-[12px] space-y-[8px]">
              <h6 className="font-inter font-semibold leading-[20px] text-[14px] text-black-100">
                {data?.title}
              </h6>
              <p className="font-inter text-[14px] leading-[22px] text-gray-100">
                <div
                  class="content"
                  dangerouslySetInnerHTML={{
                    __html: data.description.substring(0, 200),
                  }}
                />{' '}
                {data.description.length > 200 && (
                  <span className="font-bold text-blue-300 cursor-pointer">
                    Read More
                  </span>
                )}
              </p>
            </div>
            <div className="mt-[20px] flex space-x-5 justify-between items-center">
              <Button
                className="leading-[20px] text-[14px] font-semibold text-white font-inter py-[8px] px-[17px]"
                variant="primary"
              >
                view job details
              </Button>
              <div>
                {payPreference('hourly') && (
                  <div className="flex items-center space-x-2">
                    <HandGivingIcon />
                    <p className="mb-0 font-semibold text-[13px] text-green-100">
                      {payPreferenceMode('hourlyRange') ? (
                        <div>
                          ${kFormatter(data.min_hourly_rate)} -{' '}
                          {kFormatter(data.max_hourly_rate)} /hour
                        </div>
                      ) : (
                        <div>${kFormatter(data.exact_hourly_rate)} /hour</div>
                      )}
                    </p>
                  </div>
                )}
                {payPreference('annual') && (
                  <div className="flex items-center space-x-2">
                    <HandGivingIcon />
                    <p className="mb-0 font-semibold text-[13px] text-green-100">
                      {payPreferenceMode('annualRange') ? (
                        <div>
                          ${kFormatter(data.min_annual_salary)} -{' '}
                          {kFormatter(data.max_annual_salary)} /year
                        </div>
                      ) : (
                        <div>${kFormatter(data.exact_annual_salary)} /year</div>
                      )}
                    </p>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
      <ShareOnSocial
        link={shareUrl}
        linkFavicon={RLogo}
        shareTo={[
          'facebook',
          'twitter',
          'email',
          'whatsapp',
          'telegram',
          'linkedin',
        ]}
        textToShare="Check out this job post!"
        linkTitle={data.title}
        linkMetaDesc={data.duration}
        noReferer
      >
        <button className="absolute bottom-0 right-0 text-white font-semibold font-inter py-[3px] md:py-[5px] px-[8px] bg-[#52B4DA] rounded-tl-xl rounded-br-xl cursor-pointer">
          <ShareIcon size={20} fill="#fff" />
        </button>
      </ShareOnSocial>
    </div>
  )
}
