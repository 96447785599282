//packages
import React, { useState, useEffect } from 'react'
import { useSelector } from 'react-redux'

//custom components
import CandidateItem from './CandidateItem'
import SkeletonCandidateItem from 'components/Loader/SkeletonCandidateItem'
import Button from 'components/Button'
import UpgradingPlanModal from 'components/Dialog/UpgradingPlanModal'
import Pagination from 'components/pagination'

//redux & apis
import { getUser } from 'app/store/profileSlice'
import EmptyScreen from 'views/employer/profile/components/EmptyScreen'

const limit4Basic = 25

export default function Results({
  loading = false,
  candidates = [],
  selectedJob,
  currentPage,
  count,
  pageSize,
  onPageChange,
  sort,
  tab,
}) {
  const ai_selected = localStorage.getItem('ai_selected')
  const [showGetPremiumModal, setShowGetPremiumModal] = useState(false)
  const profile = useSelector(getUser)

  // if user already viewed an applicant/invitee, then go back to Results, it will auto scroll into the last click item
  useEffect(() => {
    if (!loading) {
      const element = document.getElementById(`${ai_selected}`)
      element?.scrollIntoView({ behavior: 'smooth' })
    }
  }, [loading, ai_selected])

  const sortedCandidates = [...candidates].sort((a, b) => {
    if (a.status === 'pending' && b.status !== 'pending') {
      return -1
    }
    if (a.status !== 'pending' && b.status === 'pending') {
      return 1
    }
    return 0
  })

  console.log(sortedCandidates)

  return (
    <div
      className="bg-white shadow-[14px_41px_50px_rgba(49,89,211,0.07)] rounded-3xl py-5 px-7.5"
      id="results-top"
    >
      {loading ? (
        <div className="flex flex-row items-center justify-center h-full space-x-5 animate-pulse">
          <div className="h-[32px] bg-gray-300 rounded-md w-full"></div>
        </div>
      ) : (
        <div className="text-black-100 leading-[32px] text-lg font-medium">
          {candidates.length > 0 && selectedJob?.title}
        </div>
      )}
      {loading ? (
        <SkeletonCandidateItem />
      ) : (
        <div>
          {candidates.length === 0 ? (
            <EmptyScreen tab={tab} sort={sort} />
          ) : (
            <div className="relative">
              {/* PREMIUM start comment this out no premium user */}
              {/* {profile?.plan === 'basic'
                ? candidates
                    .slice(0, limit4Basic + 1)
                    .map((v, index) => (
                      <CandidateItem
                        data={v.user}
                        selectedJob={selectedJob}
                        applicantData={v}
                        isBlur={limit4Basic <= index}
                        tab={tab}
                        id={`res${index}`}
                      />
                    ))
                : candidates.map((v, index) => (
                    <CandidateItem
                      data={v.user}
                      selectedJob={selectedJob}
                      applicantData={v}
                      tab={tab}
                      id={`res${index}`}
                    />
                  ))}
              {profile?.plan === 'basic' && count > limit4Basic && (
                <div className="absolute bottom-[80px] w-full flex justify-center">
                  <Button
                    onClick={() => setShowGetPremiumModal(true)}
                    variant="secondary"
                    className="px-5"
                  >
                    Upgrade to View More
                  </Button>
                </div>
              )} */}
              {/* end comment this out no premium user */}

              {sortedCandidates.map((v, index) => (
                <CandidateItem
                  data={v.user}
                  selectedJob={selectedJob}
                  applicantData={v}
                  tab={tab}
                  id={`res${index}`}
                />
              ))}
            </div>
          )}
        </div>
      )}
      {!loading && (
        <div className="mt-6 mb-[44px]">
          <Pagination
            onPageChange={onPageChange}
            currentPage={currentPage}
            totalCount={count}
            pageSize={pageSize}
            toTop={() => {
              localStorage.removeItem('ai_selected')
            }}
          />
        </div>
      )}
      <UpgradingPlanModal
        isOpen={showGetPremiumModal}
        closeModal={() => setShowGetPremiumModal(false)}
      />
    </div>
  )
}
