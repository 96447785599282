//packages
import React, { useEffect, useState, useCallback } from 'react'
import { useParams, useNavigate } from 'react-router-dom'

//custom components
import Avatar from 'components/Avatar'
import Button from 'components/Button'
import { Tab } from 'components/Tabs'
import AboutSection from 'views/contractor/profile/components/AboutSection'
import PortfolioSection from 'views/contractor/profile/components/PortfolioSection'

//redux & apis
import { getProfile } from 'app/store/profileSlice'
import {
  ChatIcon2,
  MessageCandidateIcon,
  CirclePlusIcon,
} from 'components/Icons'
import Header from 'views/NewHomePage/components/Header'
import IntroductionText from 'views/NewHomePage/components/IntroductionText'
import Footer from 'views/NewHomePage/components/Footer'

//assets
const TABS = ['About', 'Portfolio']

export default function CandidateProfileNonAuth() {
  const [user, setUser] = useState(null)
  const [activeTab, setActiveTab] = useState('About')

  const navigate = useNavigate()
  const { id } = useParams()

  useEffect(() => {
    const fetchUser = async () => {
      const result = await getProfile(id)
      setUser(result.data.user)
    }
    fetchUser()
  }, [])

  const handleChat = useCallback(() => {
    const redirectUrl =
      location.pathname.replace(/^\/home\/candidate/, '/employer/profile') +
      location.search
    localStorage.setItem('redirectTo', redirectUrl)

    navigate(`/auth?redirect=${encodeURIComponent(redirectUrl)}`)
  })

  return (
    <div className="bg-[#1E3E85] min-h-[100vh]">
      <Header />
      {/* <IntroductionText /> */}
      <div className="md:flex min-h-screen px-[15px] md:px-16">
        <div className="py-[37px] md:py-12 flex-1 relative mb-15 md:mb-0">
          <div className="bg-white rounded-3xl pl-[20px] md:pl-12 pr-[20px] md:pr-7 py-[20px] md:py-5 relative mt-0 md:mt-0">
            <div className="flex justify-between items-center">
              <div className="flex items-center space-x-[25px] md:space-x-8">
                <div className="w-[80px] md:w-28 h-[80px] md:h-28 flex justify-center items-center border-[4px] border-[#0049C6] rounded-full border-solid">
                  <Avatar
                    image={user?.avatar}
                    className="w-[68px] md:w-20 h-[68px] md:h-20"
                  />
                </div>
                <div>
                  <h3 className="text-black-100 text-[18px] md:text-5xl leading-[27px] md:leading-18 font-semibold">
                    {user?.name}
                  </h3>
                  <div className="flex md:hidden items-center space-x-6 mt-[15px]">
                    <div className="flex items-center space-x-[15px]">
                      <Button
                        variant="secondary"
                        className="px-[13px] md:px-8 py-[6px] md:py-4.5 flex items-center space-x-[7px] text-[12px] rounded-[5px]"
                        onClick={handleChat}
                      >
                        <CirclePlusIcon
                          size={13}
                          className="mr-[7px] text-green-100"
                        />
                        Invite
                      </Button>
                    </div>
                    <div onClick={handleChat}>
                      <ChatIcon2
                        size={18}
                        className="text-green-100 cursor-pointer"
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div className="hidden md:flex items-center space-x-6">
                <div onClick={handleChat} className="cursor-pointer">
                  <MessageCandidateIcon />
                </div>
                <div className="flex items-center space-x-5">
                  <Button
                    variant="secondary"
                    className="px-8 py-4.5"
                    onClick={handleChat}
                  >
                    Invite to Job
                  </Button>
                </div>
              </div>
            </div>
          </div>
          {/* Tabs Section */}
          <div className="mt-8">
            <Tab
              list={TABS}
              activeTab={activeTab}
              onChange={() =>
                setActiveTab((prev) =>
                  prev === 'About' ? 'Portfolio' : 'About'
                )
              }
            />
          </div>

          <div className="mt-6">
            {activeTab === 'About' && (
              <AboutSection
                profile={user}
                redirectTo={handleChat}
                showResume={false}
                showEmail={false}
              />
            )}
            {activeTab === 'Portfolio' && (
              <PortfolioSection profile={user} enableAddNew={false} />
            )}
          </div>
        </div>
      </div>
      <Footer />
    </div>
  )
}
