import clsx from 'clsx'
import { useFormik } from 'formik'
import React, { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import { Navigate, useLocation } from 'react-router-dom'
import { toast } from 'react-toastify'
import * as yup from 'yup'

//Import components
import AuthCard from 'components/Card/AuthCard'
import AboutForm from 'components/Forms/AboutForm'
import InterestForm from 'components/Forms/InterestForm'
import SkillsForm from 'components/Forms/SkillsForm'
import { AnimateSpinIcon } from 'components/Icons'

//Import actions
import AuthService from 'app/services/auth.service'
import { setUserInfo } from 'app/store/authSlice'

const initialValues = {
  avatar: '',
  video: '',
  // name: '',
  language: [],
  country: '',
  bio: '',
  job_title: '',
  num_experience: '',
  skills: [],
  work_status: 'Full Time',
  hourlyRate: '',
  annualRate: '',
  isHide: false,
  avatar: '',
  resume: '',
}

export default function RegisterForm() {
  const [activeStep, setActiveStep] = useState(0)
  const steps = ['Profile', 'Skills', 'Last']

  const dispatch = useDispatch()
  const [success, setSuccess] = useState(false)
  const [skipping, setSkipping] = useState(false)
  const [finishing, setFinishing] = useState(false)

  const [fileUrl, setFileUrl] = useState('')
  const [pictureUrl, setPictureUrl] = useState('')
  const [fileName, setFileName] = useState('')

  const location = useLocation()

  const setData = {
    fileUrl,
    setFileUrl,
    pictureUrl,
    setPictureUrl,
    fileName,
    setFileName,
  }

  const validationSchema = [
    yup.object().shape({
      // name: yup.string().required('You must enter your name'),
      language: yup
        .array()
        .of(yup.object())
        .required('You must enter Language needed'),
      country: yup.object().required('You must enter your country'),
      bio: yup.string().required('You must enter bio'),
    }),
    yup.object().shape({
      skills: yup
        .array()
        .min(3, 'Choose at least 3 skills')
        .required('required'),
      job_title: yup
        .string()
        .min(5, 'A minimum of 5 chars is expected')
        .max(60, 'A maximum of 60 chars is expected')
        .required('You must enter professional tagline'),
    }),
  ]
  const currentValidationSchema = validationSchema[activeStep]

  const formik = useFormik({
    initialValues,
    validationSchema: currentValidationSchema,
    onSubmit: (values) => {
      if (activeStep === 0 || activeStep === 1) {
        setActiveStep((prev) => prev + 1)
        formik.setSubmitting(false)
      } else handleSubmit(values)
    },
  })

  const handleBack = (event) => {
    event.preventDefault()
    setActiveStep((prevActiveStep) => prevActiveStep - 1)
  }

  const handleNext = (event) => {
    event.preventDefault()
    setActiveStep((prevActiveStep) => prevActiveStep + 1)
  }

  const handleSkipStep2 = () => {
    setActiveStep(steps.length - 1)
  }

  const handleSkip = () => {
    setActiveStep(steps.length - 1)
    setSkipping(true)
  }

  // check if the active step is on 2nd step to open the popup notification when click X
  useEffect(() => {
    if (activeStep !== 0) {
      localStorage.setItem('show_signup_notification', true)
    } else {
      localStorage.setItem('show_signup_notification', false)
    }
  }, [activeStep])

  const handleSubmit = async (model) => {
    // formik.setSubmitting(true)

    const { email, password, name } = location.state

    const param = {
      name,
      email,
      password,
      language: model.language.map((v) => v.id),
      country: model.country.name,
      bio: model.bio,
      job_title: model.job_title,
      num_experience: model.num_experience,
      skills: model.skills.map((v) => v.id),
      work_status: model.work_status,
      hourly_rate: model.hourlyRate,
      annual_rate: model.annualRate,
      isHide: model.isHide,
      avatar: model.avatar,
      video: model.video,
      resume: model.resume,
      type: 'contractor',
    }
    setFinishing(true)
    AuthService.createUser(param)
      .then((res) => {
        toast.success('User created successfully.')
        dispatch(setUserInfo(res))
        const userData = res.data.user
        try {
          localStorage.setItem('verifId', userData.id)
          localStorage.setItem('regEmail', userData.email)
        } catch (error) {
          console.error('Error setting verifId in local storage:', error)
        }
        setSuccess(true)
      })
      .catch((err) => {
        toast.error('There is error when creating user.')
        console.log('debug: err', err)
      })
      .finally(() => {
        formik.setSubmitting(false)
        setFinishing(false)
      })
  }

  function getStepContent(step, setData) {
    switch (step) {
      case 0:
        return (
          <AboutForm
            formik={formik}
            setPictureUrl={setData.setPictureUrl}
            pictureUrl={setData.pictureUrl} //url for saving avatar
          />
        )
      case 1:
        return (
          <SkillsForm
            formik={formik}
            setFileUrl={setData.setFileUrl} //Set url for the resume upload
            setFileName={setData.setFileName} //Set file name for the resume, it is showing in the pannel
            fileName={setData.fileName} //Variables to show
          />
        )
      case 2:
        return <InterestForm formik={formik} />
      default:
        return 'Unknown step'
    }
  }

  if (!location.state) {
    return <Navigate to="/auth/register" />
  }

  console.log('formik.errors', formik.errors)

  return (
    <div className="w-116 md:w-157 mx-auto py-[10vh]">
      <AuthCard className="py-8 px-8">
        <div>
          {success ? (
            <Navigate to="/auth/verify" />
          ) : (
            <form onSubmit={formik.handleSubmit}>
              <div>{getStepContent(activeStep, setData)}</div>
              <div className="w-full">
                {activeStep === 0 && (
                  <div className="w-full flex md:justify-end">
                    <button
                      type="submit"
                      className="w-full md:w-auto bg-blue-100 hover:opacity-80 rounded-2xl font-bold text-[14px] md:text-sm text-white px-20 text-center py-4 disabled:opacity-50"
                      disabled={formik.isSubmitting}
                    >
                      Next: Your Skills
                    </button>
                  </div>
                )}
                {(activeStep === 1 || activeStep === 2) && (
                  <div
                    className={`grid grid-cols-2 gap-x-4 gap-y-[10px] ${
                      activeStep !== 2 && 'md:grid-cols-3'
                    }`}
                  >
                    <button
                      className="w-full justify-center items-center flex rounded-2xl bg-green-100 py-[18px] font-bold text-white text-[14px] md:text-sm leading-5 text-center cursor-pointer hover:opacity-80 disabled:opacity-50"
                      disabled={formik.isSubmitting}
                      onClick={handleBack}
                    >
                      Back
                    </button>
                    {activeStep === 1 ? (
                      <>
                        <button
                          onClick={handleSkipStep2}
                          disabled={formik.isSubmitting}
                          type="submit"
                          className="flex justify-center items-center w-full rounded-2xl border-[2px] border-solid border-gray-400 py-[18px] font-bold text-black-100 text-[14px] md:text-sm leading-5 text-center cursor-pointer hover:text-green-100 hover:border-green-100 disabled:opacity-50"
                        >
                          Skip
                          {skipping && (
                            <AnimateSpinIcon className="w-6 h-6 ml-2" />
                          )}
                        </button>
                        <button
                          disabled={formik.isSubmitting}
                          type="submit"
                          className={clsx(
                            'flex justify-center items-center w-full rounded-2xl bg-blue-100 py-[18px] font-bold text-white text-[14px] md:text-sm leading-5 text-center cursor-pointer hover:opacity-80  disabled:opacity-50',
                            activeStep !== 2 && 'col-span-2 md:col-span-1'
                          )}
                        >
                          Next
                          {finishing && (
                            <AnimateSpinIcon className="w-6 h-6 ml-2" />
                          )}
                        </button>
                      </>
                    ) : null}

                    {activeStep === 2 && (
                      <>
                        <button
                          onClick={handleSkip}
                          disabled={formik.isSubmitting}
                          type="submit"
                          className="flex justify-center items-center w-full rounded-2xl border-[2px] border-solid border-gray-400 py-[18px] font-bold text-black-100 text-[14px] md:text-sm leading-5 text-center cursor-pointer hover:text-green-100 hover:border-green-100 disabled:opacity-50"
                        >
                          Skip
                          {skipping && (
                            <AnimateSpinIcon className="w-6 h-6 ml-2" />
                          )}
                        </button>
                        <button
                          disabled={formik.isSubmitting}
                          type="submit"
                          className={clsx(
                            'flex justify-center items-center w-full rounded-2xl bg-blue-100 py-[18px] font-bold text-white text-[14px] md:text-sm leading-5 text-center cursor-pointer hover:opacity-80  disabled:opacity-50',
                            activeStep !== 2 && 'col-span-2 md:col-span-1'
                          )}
                        >
                          Finish
                          {finishing && (
                            <AnimateSpinIcon className="w-6 h-6 ml-2" />
                          )}
                        </button>
                      </>
                    )}
                  </div>
                )}
              </div>
            </form>
          )}
        </div>
      </AuthCard>
    </div>
  )
}
