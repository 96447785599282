import React, { useState } from "react";

import SignupCard from "components/Register/SignUpCard";

function Register() {
  const [open, setOpen] = useState(true);

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <div className="w-116 mx-auto">
      <SignupCard open={open} handleClose={handleClose} />
    </div>
  );
}

export default Register;
