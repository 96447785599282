//packages
import React from "react";

//custom components
import LoginCard from "components/Login/LoginCard";

function LoginPage() {

  return (
    <div className="w-116 mx-auto">
      <LoginCard />
    </div>
  );
}

export default LoginPage;
