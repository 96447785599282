//packages
import React, { useState } from 'react'

//custom components
import { LocationIcon, Right } from 'components/Icons'
import DefaultAvatar from 'assets/images/default-avatar.png'
import { getClickableLink } from 'app/utils/function'

export default function ContactInformation({ profile }) {
  const [openBio, setOpenBio] = useState(false);
  console.log('debug: profile', profile)
  return (
    <div className="bg-white rounded-[15px] md:rounded-3xl p-[20px] md:p-7">
      <div className="flex items-center space-x-[30px]">
        <div className="w-[70px] h-[70px] rounded-full overflow-hidden">
          <img
            className="object-fill rounded-full"
            src={profile.logo ? profile.logo : DefaultAvatar}
            alt="company-logo"
          />
        </div>
        <div className="text-[#11142D] text-[18px] font-medium font-poppins">
          {profile.companyName}
        </div>
      </div>
      {profile.emType === 'individual' ? (
        <section
          id="contact-body-company-level-user"
          className="mt-[35px] space-y-[20px]"
        >
          <div className="flex items-center space-x-[29px]">
            <label className="w-[70px] text-[#808191] text-[13px] font-medium leading-[18px]">
              First Name
            </label>
            <div className="text-[#404256] text-[14px] leading-[21px] font-medium font-inter flex items-center">
              {profile.firstName}
            </div>
          </div>
          <div className="flex items-center space-x-[29px]">
            <label className="w-[70px] text-[#808191] text-[13px] font-medium leading-[18px]">
              Last Name
            </label>
            <div className="text-[#404256] text-[14px] leading-[21px] font-medium font-inter flex items-center">
              {profile.lastName}
            </div>
          </div>
          {/* <div className="flex items-center space-x-[29px]">
            <label className="w-[70px] flex-none text-[#808191] text-[13px] font-medium leading-[18px]">
              Email
            </label>
            <div className="text-[14px] leading-[21px] font-medium font-inter flex items-center w-auto truncate overflow-auto text-[#52B4DA]">
              {profile.email}
            </div>
          </div> */}
          <div className="flex items-center space-x-[29px]">
            <label className="w-[70px] flex-none text-[#808191] text-[13px] font-medium leading-[18px]">
              Location
            </label>
            <div className="text-[14px] leading-[21px] font-medium font-inter flex items-center w-auto truncate overflow-auto text-[#404256]">
              {profile.location}
            </div>
          </div>
        </section>
      ) : (
        <section id="contact-body-company-level-user">
          <div>
            <div className="flex space-x-2 mt-[20px] md:mt-8">
              <LocationIcon className="text-green-100" size={14} />
              <span className="font-medium text-[13px] md:text-sm leading-[16px] md:leading-4 text-gray-100">
                HQ Country
              </span>
            </div>
            <div className="text-black-100 leading-[18px] md:leading-4 font-medium text-[13px] md:text-sm mt-2">
              {profile.country}
            </div>
          </div>
          <div className="mt-4"></div>
          <div className="space-y-5">
            <div className="font-medium text-[13px] md:text-sm leading-4 text-gray-100 mt-6">
              Industry
            </div>
            <div className="flex mt-[10px] md:mt-4 flex-wrap">
              {profile.industry.map((v) => (
                <div
                  key={v}
                  className="mr-[10px] border inline-block border-[#E0E5F9] border-solid rounded-lg py-2 px-2 text-black-100 leading-4 text-[12px] font-inter md:text-sm font-semibold mb-[10px]"
                >
                  {v}
                </div>
              ))}
            </div>
            <div className="flex justify-between items-center">
              <span className="font-medium text-[13px] md:text-sm leading-4 text-gray-100">
                Employee Headcount
              </span>
              <span className="text-[rgba(17,20,45,0.8)] leading-5 font-medium text-sm">
                {profile.companySize}+ employees
              </span>
            </div>
            <div className="flex justify-between items-center gap-3">
              <span className="font-medium text-[13px] md:text-sm leading-4 text-gray-100">
                Website
              </span>
              <a
                target="_blank"
                href={getClickableLink(profile.website)}
                className="text-green-100 truncate"
              >
                <span className="leading-5 font-medium text-sm">
                  {profile.website}
                </span>
              </a>
            </div>
            <div className="font-medium text-[13px] md:text-sm leading-4 text-gray-100 mt-6">
              About
            </div>
            <div className="mt-[10px] md:mt-4 text-[14px] font-medium text-[#11142DCD] relative">
              {openBio ? (
                <div>
                  <div
                    class="content"
                    dangerouslySetInnerHTML={{
                      __html: profile.bio,
                    }}
                  />
                  <span className="font-semibold text-[13px] text-[#1E3E85] cursor-pointer mt-4 flex gap-x-2" onClick={() => setOpenBio(false)}>
                    Read Less <Right/>
                  </span>
                </div>
              ) : (
                <div>
                  <div
                    class="content"
                    dangerouslySetInnerHTML={{
                      __html: profile.bio.substring(0, 200),
                    }}
                  />{' '}
                  {profile.bio.length > 200 && (
                    <div>
                      <span className="font-semibold text-[13px] text-[#1E3E85] cursor-pointer mt-4 flex gap-x-2" onClick={() => setOpenBio(true)}>
                        Read More <Right/>
                      </span>
                      <div class="backdrop-blur-[1px] bg-white/50 h-[38px] w-full absolute bottom-[32px]"/>
                    </div>
                  )}
                  
                </div>
              )}
            </div>
          </div>
        </section>
      )}
    </div>
  )
}
