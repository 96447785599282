//packages
import moment from 'moment'
import React, { useCallback, useEffect, useState } from 'react'
import { getOrCreateChat } from 'react-chat-engine'
import { useSelector, useDispatch } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { toast } from 'react-toastify'

import AcceptInvitationModal from '../components/AcceptInvitationModal'
import RejectInvitationConfirmModal from '../components/RejectInvitationConfirmModal'
import Avatar from 'components/Avatar'
import Button from 'components/Button'
import { DoubleCheckIcon, ChatIcon2 } from 'components/Icons'

//redux
import {
  approveInvite,
  fetchApplicantsList,
  fetchInvitesList,
  rejectInvite,
  getInvitesListForEmployer,
} from 'app/store/applicantsSlice'
import { getUser } from 'app/store/profileSlice'

export default function TopHeader({
  job,
  handleSubmitApplicationModal,
  submittedSuccess,
}) {
  const applied = job.applied || job.invited
  const profile = useSelector(getUser)
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const [handleAcceptLoading, setHandleAcceptLoading] = useState(false)
  const [resumeUploading, setResumeUploading] = useState(false)
  const [openAcceptInviationModal, setOpenAcceptInvitationModal] =
    useState(false)
  const [fileUrl, setFileUrl] = useState('') //resume url
  const [openRejectInvitationModal, setOpenRejectInvitationModal] =
    useState(false)

  const invites = useSelector(getInvitesListForEmployer)

  const selectedApplicant =
    invites.length !== 0 && invites.filter((v) => v.jobId === job.id)

  const fetchApplications = useCallback(() => {
    if (profile) {
      const params = {
        limit: 100,
        offset: 0,
        userId: profile?.id,
      }
      dispatch(fetchApplicantsList(params))
      dispatch(fetchInvitesList(params))
    }
  }, [dispatch, profile])

  const handleSendMessage = () => {
    const creds = {
      projectID: process.env.REACT_APP_CHAT_ENGINE_PROJECT_ID,
      userName: profile.id,
      userSecret: profile.id,
    }
    getOrCreateChat(
      creds,
      { is_direct_chat: true, usernames: [job.createdBy.id], title: job.title },
      (data) => {
        navigate('/contractor/messages')
      }
    )
  }

  const handleAcceptModal = useCallback(() => {
    setOpenAcceptInvitationModal(true)
  }, [setOpenAcceptInvitationModal])

  const handleAccept = useCallback(async () => {
    setOpenAcceptInvitationModal(false)
    const params = {
      inviteId: selectedApplicant[0].id,
      resumeUrl: fileUrl,
    }
    const resultAction = await dispatch(approveInvite(params))
    if (approveInvite.fulfilled.match(resultAction)) {
      toast.success('Accepted Invite Successfully')
    } else {
      toast.error('Accepted Invite Failed')
    }
    submittedSuccess()
    setHandleAcceptLoading(false)
  }, [setOpenAcceptInvitationModal, selectedApplicant])

  const handleRejectModal = useCallback(() => {
    setOpenRejectInvitationModal(true)
  }, [setOpenRejectInvitationModal])

  const handleReject = useCallback(async () => {
    setOpenRejectInvitationModal(false)
    const params = {
      inviteId: selectedApplicant[0].id,
    }
    const resultAction = await dispatch(rejectInvite(params))
    if (rejectInvite.fulfilled.match(resultAction)) {
      toast.success('Denied Invite successfully')
    } else {
      toast.error('Denied Invite failed')
    }
    submittedSuccess()
  }, [setOpenRejectInvitationModal, selectedApplicant])

  return (
    <div className="w-full">
      <div className="hidden md:block w-full h-22 bg-job-detail-header bg-no-repeat bg-top bg-[length:100%_352px] py-[80px] px-16 space-y-2">
        <div className="text-white leading-[60px] font-semibold text-[40px]">
          {job.createdBy.companyName}
        </div>
        <div className="text-[rgba(255,255,255,0.699929)] leading-6 text-lg font-medium">
          {job.createdBy.country}
        </div>
      </div>
      <div className="md:mx-16 rounded-3xl justify-between bg-white py-[15px] md:py-6 px-[15px] md:px-12 md:flex md:space-x-8 md:-mt-[85px] shadow-md items-center">
        <div className="flex flex-1 items-center space-x-[15px] md:space-x-8">
          <div className="w-[80px] md:w-32 h-[80px] md:h-32 flex justify-center items-center border-[4px] border-[#0049C6] rounded-full border-solid">
            <Avatar
              image={
                job.createdBy.emType === 'company'
                  ? job.createdBy.logo
                  : job.createdBy.avatar
              }
              className="w-[70px] md:w-28 h-[70px] md:h-28"
            />
          </div>
          <div className="flex-1 space-y-[5px] md:space-y-2.5">
            <p className="text-black-100 leading-[20px] md:leading-10 text-[16px] md:text-3xl font-semibold">
              {job.title}
            </p>
            <p className="text-black-100 opacity-50 md:opacity-60 font-medium text-[12px] md:text-lg leading-[18px] md:leading-6">
              {moment(job.createdAt).fromNow()}
            </p>
          </div>
        </div>

        <div className="w-full md:w-auto mt-[20px] md:mt-0">
          {applied ? (
            <div>
              {job.invited ? (
                job.invitedStatus !== 'accepted' ? (
                  job.invitedStatus !== 'rejected' ? (
                    job.status === 'active' && (
                      <div className="md:flex justify-center md:space-x-[19px] space-x-0 md:space-y-0 space-y-5">
                        <div className="md:inline-block block md:w-auto w-full">
                          <Button
                            variant="secondary"
                            className="flex-1 md:w-auto w-full px-4"
                            onClick={handleAcceptModal}
                          >
                            Apply for Job
                          </Button>
                        </div>

                        <div className="md:inline-block block md:w-auto w-full">
                          <Button
                            className="flex-1 md:w-auto w-full px-4"
                            variant="error"
                            onClick={handleRejectModal}
                          >
                            Reject Invite
                          </Button>
                        </div>
                      </div>
                    )
                  ) : (
                    <button
                      disabled={job.invited}
                      variant="apply"
                      className="space-x-1 font-inter w-full md:w-auto flex items-center cursor-pointer justify-center bg-[#DEDFE3] text-blue-300 px-11 py-[16px] md:py-4.5 text-[14px] md:text-sm leading-5 rounded-2xl"
                      onClick={handleSubmitApplicationModal}
                    >
                      <DoubleCheckIcon className="text-[#52B4DA] mr-1" />
                      Invite{' '}
                      <span className="capitalize">{job.invitedStatus}</span>
                    </button>
                  )
                ) : (
                  // <button
                  //   onClick={handleSendMessage}
                  //   className="space-x-2 font-inter w-full md:w-auto flex items-center cursor-pointer justify-center bg-[#1E3E84] text-white px-11 py-[16px] md:py-4.5 text-[14px] md:text-sm leading-5 rounded-2xl font-bold "
                  // >
                  //   <ChatIcon2 size={20} />
                  //   <span className="">Message</span>
                  // </button>
                  <button
                    disabled={job.applied}
                    variant="apply"
                    className="space-x-1 font-inter w-full md:w-auto flex items-center cursor-pointer justify-center bg-[#DEDFE3] text-blue-300 px-11 py-[16px] md:py-4.5 text-[14px] md:text-sm leading-5 rounded-2xl"
                    onClick={handleSubmitApplicationModal}
                  >
                    <DoubleCheckIcon className="text-[#52B4DA] mr-1" />
                    Application <span className="capitalize">Submitted</span>
                  </button>
                )
              ) : job.appliedStatus !== 'accepted' ? (
                <button
                  disabled={job.applied}
                  variant="apply"
                  className="space-x-1 font-inter w-full md:w-auto flex items-center cursor-pointer justify-center bg-[#DEDFE3] text-blue-300 px-11 py-[16px] md:py-4.5 text-[14px] md:text-sm leading-5 rounded-2xl"
                  onClick={handleSubmitApplicationModal}
                >
                  <DoubleCheckIcon className="text-[#52B4DA] mr-1" />
                  Application{' '}
                  <span className="capitalize">{job.appliedStatus}</span>
                </button>
              ) : (
                // <button
                //   onClick={handleSendMessage}
                //   className="space-x-2 font-inter w-full md:w-auto flex items-center cursor-pointer justify-center bg-[#1E3E84] text-white px-11 py-[16px] md:py-4.5 text-[14px] md:text-sm leading-5 rounded-2xl font-bold "
                // >
                //   <ChatIcon2 size={20} />
                //   <span className="">Message</span>
                // </button>
                <button
                  disabled={job.applied}
                  variant="apply"
                  className="space-x-1 font-inter w-full md:w-auto flex items-center cursor-pointer justify-center bg-[#DEDFE3] text-blue-300 px-11 py-[16px] md:py-4.5 text-[14px] md:text-sm leading-5 rounded-2xl"
                  onClick={handleSubmitApplicationModal}
                >
                  <DoubleCheckIcon className="text-[#52B4DA] mr-1" />
                  Application <span className="capitalize">Submitted</span>
                </button>
              )}
            </div>
          ) : (
            <Button
              disabled={applied}
              variant="secondary"
              className="font-inter w-full md:w-auto px-16 py-[16px] md:py-4.5 font-bold text-[14px] md:text-sm leading-[20px]"
              onClick={handleSubmitApplicationModal}
            >
              Apply
            </Button>
          )}
        </div>
      </div>

      <AcceptInvitationModal
        resumeUploading={resumeUploading}
        setResumeUploading={setResumeUploading}
        isOpen={openAcceptInviationModal}
        closeModal={() => {
          setResumeUploading(false)
          setOpenAcceptInvitationModal(false)
        }}
        handleAccept={handleAccept}
        setFileUrl={setFileUrl}
        handleAcceptLoading={handleAcceptLoading}
      />

      <RejectInvitationConfirmModal
        isOpen={openRejectInvitationModal}
        closeModal={() => setOpenRejectInvitationModal(false)}
        handleReject={handleReject}
      />
    </div>
  )
}
