//packages
import React from 'react'
import Carousel from 'react-multi-carousel'
import clsx from 'clsx'

//custom components
import { CircleArrowIcon } from 'components/Icons'
import ContractorCard from './ContractorCard'

const responsive = {
  superLargeDesktop: {
    // the naming can be any, depends on you.
    breakpoint: { max: 4000, min: 3000 },
    items: 5,
  },
  desktop: {
    breakpoint: { max: 3000, min: 1024 },
    items: 3,
  },
  tablet: {
    breakpoint: { max: 1024, min: 464 },
    items: 2,
  },
  mobile: {
    breakpoint: { max: 464, min: 0 },
    items: 1,
  },
}

const CustomButtonGroup = ({ next, previous, carouselState }) => {
  return (
    <div className="absolute w-full justify-between hidden md:flex -top-16 z-10">
      <div onClick={() => previous()}>
        <CircleArrowIcon className="cursor-pointer" />
      </div>
      <div onClick={() => next()} className="-mt-4">
        <CircleArrowIcon className="rotate-180 cursor-pointer" />
      </div>
    </div>
  )
}

const CustomDot = ({ onClick, ...rest }) => {
  const {
    onMove,
    index,
    active,
    carouselState: { currentSlide, deviceType },
  } = rest
  // onMove means if dragging or swiping in progress.
  // active is provided by this lib for checking if the item is active or not.
  return (
    <button
      className={clsx(
        active ? 'bg-[#1E3E85] w-[28px]' : 'w-[16px] bg-[#E0E0E4]',
        'rounded-lg h-[3px]'
      )}
      onClick={() => onClick()}
    ></button>
  )
}

export default function TopContractorSection({ candidates }) {
  return (
    <section className="md:pt-36 pt-16">
      <div className="z-0">
        <h2 className="text-black-100 font-semibold leading-[30px] md:leading-18 text-[20px] md:text-5xl text-center">
          Top Job Seekers
        </h2>
        <h6 className="text-gray-100 text-[14px] md:text-lg text-center max-w-[583px] md:mx-auto mx-[15px] mt-[12px] md:mt-5 font-light">
          Here are a few high-quality, available for hire today
        </h6>
      </div>
      <div className="pt-[53px] md:pt-20 relative mx-[15px] md:mx-0">
        <Carousel
          arrows={false}
          responsive={responsive}
          customButtonGroup={<CustomButtonGroup />}
          showDots
          customDot={<CustomDot />}
          // dotListClass="space-x-[8px] top-[30px] flex md:hidden"
          dotListClass="space-x-[8px] bottom-[-10px] flex md:hidden"
          draggable
          swipeable
        >
          {candidates.map((candidate) => (
            <ContractorCard key={candidate.id} data={candidate} />
          ))}
        </Carousel>
      </div>
    </section>
  )
}
