//packages
import React, { Fragment, useEffect, useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { toast } from 'react-toastify'

//custom components
import CancelPopupModal from './components/CancelPopupModal'
import CardBody from './components/CardBody'

//redux & api
import { getUser } from 'app/store/profileSlice'
import { updateEmployerPlan } from 'app/store/profileSlice'
import { updateUserLastActivity } from 'app/services/auth.service'

export default function Setting() {
  const [loading, setLoading] = useState(false)
  const [showCancelMembershipModal, setShowCancelMembershipModal] =
    useState(false)

  const dispatch = useDispatch()
  const profile = useSelector(getUser)

  useEffect(async () => {
    //Update user last activity
    if (profile) {
      await updateUserLastActivity(profile.id)
    }
  }, [])

  const cancelHandler = async () => {
    setLoading(true)
    try {
      const param = {
        userId: profile.id,
        premium: false,
      }
      const resultAction = await dispatch(updateEmployerPlan(param))
      if (updateEmployerPlan.fulfilled.match(resultAction)) {
        toast.success('Cancelled Membership Successfully')
      } else {
        if (resultAction.payload) {
          toast.error(
            'Upgrade account failed: ' + resultAction.payload.errorMessage
          )
        } else {
          toast.error('Upgrade account failed' + resultAction.error.message)
        }
      }
      setShowCancelMembershipModal(false)
    } catch (err) {
      console.log(err)
    } finally {
      setLoading(false)
    }
  }

  if (!profile) return null

  return (
    <div className="md:overflow-auto md:h-screen w-full pb-[70px] md:pb-[139px]">
      <div className="w-full max-w-4xl mx-auto">
        <div className="text-black-100 py-12 pl-[15px] md:pl-0 pr-[15px] md:pr-0">
          <h2 className="text-[14px] md:text-[24px] font-medium font-poppins">
            Welcome, {`${profile.firstName}`}!
          </h2>
          <h1 className="text-[24px] md:text-[48px] font-semibold font-poppins">
            Account Settings
          </h1>
        </div>
        <div className="flex justify-center mx-[15px] md:mx-auto max-w-[1075px]">
          <div className="bg-white rounded-3xl shadow-setting md:min-w-[694px]">
            <CardBody
              profile={profile}
              isPremium={profile.plan === 'premium'}
              setShowCancelMembershipModal={setShowCancelMembershipModal}
            />
          </div>
        </div>
      </div>
      <CancelPopupModal
        isOpen={showCancelMembershipModal}
        closeModal={() => {
          setShowCancelMembershipModal(false)
        }}
        profile={profile}
        cancelHandler={cancelHandler}
        loading={loading}
      />
    </div>
  )
}
