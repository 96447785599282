import React from 'react'

const WordPress = () => {
  return (
    <div style={{ width: '100%', height: '100vh' }}>
      <iframe
        src="https://remoterecruit.ai"
        style={{ width: '100%', height: '100%', border: 'none' }}
        title="Remote Jobs"
      ></iframe>
    </div>
  )
}

export default WordPress
