import React from 'react'

export default function LinkedinIcon({
  className = 'text-[#DFE1F2]',
  size = 16,
  color = "#fff",
}) {
  return (
    // <svg
    //   className={className}
    //   width={size}
    //   viewBox="0 0 22 22"
    //   fill="none"
    //   xmlns="http://www.w3.org/2000/svg"
    // >
    //   <path
    //     fillRule="evenodd"
    //     clipRule="evenodd"
    //     d="M10.6631 8.53332V9.66465C11.6508 7.69855 15.5337 7.66523 15.665 10.5147L15.6685 10.6647V15.6647L13.6638 15.666V11.6653C13.6638 8.8887 10.7728 9.64374 10.6661 11.562L10.6631 11.664V15.666H8.66779V8.53332H10.6631V9.66465V8.53332ZM7.66233 8.66432V15.6657H5.66699V8.66432H7.66233ZM6.66713 6.33398C7.21846 6.33398 7.66513 6.78065 7.66513 7.33198C7.66513 7.88265 7.21846 8.32932 6.66713 8.32932C6.11646 8.32932 5.66979 7.88265 5.66979 7.33198C5.66979 6.78065 6.11646 6.33398 6.66713 6.33398Z"
    //     fill="currentColor"
    //   />
    // </svg>
    <svg
      width={size}
      fill={color}
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 448 512"
    >
      <path d="M416 32H31.9C14.3 32 0 46.5 0 64.3v383.4C0 465.5 14.3 480 31.9 480H416c17.6 0 32-14.5 32-32.3V64.3c0-17.8-14.4-32.3-32-32.3zM135.4 416H69V202.2h66.5V416zm-33.2-243c-21.3 0-38.5-17.3-38.5-38.5S80.9 96 102.2 96c21.2 0 38.5 17.3 38.5 38.5 0 21.3-17.2 38.5-38.5 38.5zm282.1 243h-66.4V312c0-24.8-.5-56.7-34.5-56.7-34.6 0-39.9 27-39.9 54.9V416h-66.4V202.2h63.7v29.2h.9c8.9-16.8 30.6-34.5 62.9-34.5 67.2 0 79.7 44.3 79.7 101.9V416z"/>
    </svg>
  )
}
