//packages
import { useNavigate } from 'react-router-dom'

//custom components
import DefaultAvatar from 'assets/images/default-avatar.png'

export default function ({ blog }) {
  const navigate = useNavigate()
  const onViewJob = () => {
    navigate(`/blog/${blog.id}`)
  }
  return (
    <div className="md:w-125 shadow-[4px_4px_16px_rgba(49,89,211,0.06)] rounded-3xl p-8">
      <div className="block space-x-4">
        <div className="w-full h-[250px] flex-none shadow-[0px_4px_8px_rgba(225,227,242,0.32)]">
          <img
            className="w-full h-full rounded-lg object-cover"
            src={blog.image ? blog.image : DefaultAvatar}
          />
        </div>
      </div>
      <div className="mt-3">
        <div className="flex space-x-1 items-center">
          <div className="w-[2px] h-[2px] bg-gray-100" />
          <p className="text-green-100 text-uppercase leading-4 font-semibold text-[11px] uppercase">
            {blog.date_post}
          </p>
        </div>

        <p className="text-black-100 font-medium text-[24px] leading-10 mt-3">
          {blog.title}
        </p>
      </div>

      <div className="text-[rgba(17,20,45,0.72)] text-sm leading-6 h-[150px] mt-6 max-h-[230px] line-clamp-4">
        <div class="content" dangerouslySetInnerHTML={{ __html: blog.content.substr(0, 300) }} /> {"..."}
      </div>
      <div className="mt-4">
        <button
          onClick={onViewJob}
          className="text-blue-100 text-sm leading-5 font-semibold py-3 px-6 border-[1.5px] border-[rgba(83,180,218,0.7)] rounded-xl btn-hover"
        >
          View Post
        </button>
      </div>
    </div>
  )
}
