//packages
import { useFormik } from 'formik'
import React, { useState } from 'react'
import * as yup from 'yup'
import { toast } from 'react-toastify'

//custom components
import { AnimateSpinIcon } from 'components/Icons'
import AuthCard from 'components/Card/AuthCard'
import TextField from 'components/Input/TextFieldError'

//api & redux
import AuthService from 'app/services/auth.service'

const schema = yup.object().shape({
  email: yup
    .string()
    .email('You must enter a valid email')
    .required('You must enter a email'),
})

export default function ForgotPassword() {
  const [serverError, setServerError] = useState('')
  const [isSubmitted, setIsSubmitted] = useState(false)
  const formik = useFormik({
    initialValues: {
      email: localStorage.getItem('forgetEmail')
        ? localStorage.getItem('forgetEmail')
        : '',
    },
    validationSchema: schema,
    onSubmit: (values) => {
      handleSubmit(values)
    },
  })

  const handleSubmit = (values) => {
    formik.setSubmitting(true)
    const param = {
      email: values.email,
    }
    AuthService.forgotPassword(param)
      .then((_) => {
        toast.success(
          'Password reset link has been sent to your email. Please check!'
        )
        setIsSubmitted(true)
      })
      .catch((err) => {
        toast.error('There is error when send password link')
        setServerError('No account found with that email!')
        setTimeout(() => {
          setServerError('')
          formik.resetForm()
        }, 2000)
      })
      .finally(() => {
        formik.setSubmitting(false)
      })
  }

  return (
    <div className="w-116 mx-auto">
      <AuthCard>
        {isSubmitted ? (
          <div>
            <h2 className="text-black-100 text-[24px] md:text-m4xl font-semibold leading-[36px] md:leading-13">
              Thank you!
            </h2>
            <div className="text-[12px] md:text-base font-medium md:font-light leading-[18px] md:leading-5 mt-5 text-gray-300 md:text-black-100">
              Check your email and click the link to reset password.
            </div>
            <button
              disabled={formik.isSubmitting || serverError}
              onClick={formik.handleSubmit}
              className="flex justify-center transition-all duration-300 mt-[40px] bg-blue-100 hover:opacity-80 rounded-2xl font-bold text-[14px] md:text-sm text-white w-full text-center py-[18px] disabled:opacity-50"
            >
              Resend
              {formik.isSubmitting && (
                <AnimateSpinIcon className="w-6 h-6 ml-2" />
              )}
            </button>
          </div>
        ) : (
          <form onSubmit={formik.handleSubmit}>
            <h2 className="text-black-100 text-[24px] md:text-m4xl font-semibold leading-[36px] md:leading-13">
              Forgot Password
            </h2>
            <div className="text-[12px] md:text-base font-medium md:font-light leading-[18px] md:leading-5 mt-5 text-gray-300 md:text-black-100">
              Enter your email below and if there is an account associated with
              it we will send a link to reset your password.
            </div>
            <div className="mt-[40px]">
              <TextField
                label="Email"
                type="email"
                name="email"
                id="email"
                value={formik.values.email}
                error={serverError}
                onChange={formik.handleChange}
                errorClassName="absolute top-0 text-[14px]"
              />
            </div>
            <button
              disabled={formik.isSubmitting}
              type="submit"
              className="flex justify-center transition-all duration-300 mt-[40px] bg-blue-100 hover:opacity-80 rounded-2xl font-bold text-[14px] md:text-sm text-white w-full text-center py-[18px] disabled:opacity-50"
            >
              Submit
              {formik.isSubmitting && (
                <AnimateSpinIcon className="w-6 h-6 ml-2" />
              )}
            </button>
          </form>
        )}
      </AuthCard>
    </div>
  )
}
