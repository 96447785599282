import { useStripe, useElements, PaymentElement } from '@stripe/react-stripe-js';
import { useState } from 'react'
import { TailSpin } from 'react-loader-spinner';
import { toast } from 'react-toastify'

//custom component
import Button from 'components/Button';

const CheckoutForm = ({ onSuccessPaid, onBack }) => {
  const [loading, setLoading] = useState(false);
  const stripe = useStripe();
  const elements = useElements();

  const handleSubmit = async (event) => {
    try {
      setLoading(true)
      // We don't want to let default form submission happen here,
      // which would refresh the page.
      event.preventDefault();

      if (!stripe || !elements) {
        // Stripe.js hasn't yet loaded.
        // Make sure to disable form submission until Stripe.js has loaded.
        return;
      }

      const result = await stripe.confirmPayment({
        //`Elements` instance that was used to create the Payment Element
        elements,
        confirmParams: {
          return_url: "http://localhost:3000/auth/employee/register",
        },
        redirect: "if_required"
      });

      if (result.error) {
        // Show error to your customer (for example, payment details incomplete)
        toast.error('Payment failed')
      } else {
        onSuccessPaid();
      }
    } catch (err) {
      console.log('debug: error', err)
    } finally {
      setLoading(false)
    }
  };

  return (
    <form className='mt-8' onSubmit={handleSubmit}>
      <PaymentElement />
      <div className='text-black-100 leading-[20px] md:leading-4 text-[14px] md:text-sm opacity-80 mt-0 md:mt-8'>
        You will be charged $79.99 today and be instantly upgraded to Premium.
      </div>
      <div className='w-full grid md:grid-cols-2 gap-x-6 mt-8 gap-y-[10px] md:gap-y-0'>
        <Button
          onClick={(e) => {
            e.preventDefault();
            onBack()
          }}
          variant="outline"
          className="text-black-100 font-bold leading-5 text-[14px] md:text-sm"
        >
          Back
        </Button>
        <Button
          type="submit"
          disabled={loading || !stripe}
          variant="primary"
          className="leading-[20px] text-[14px] font-bold text-white rounded-[16px]"
        >
          {loading && (
            <div className="mr-2">
              <TailSpin height={20} width={20} />
            </div>
          )}
          Complete Payment
        </Button>
      </div>
    </form>
  );
};

export default CheckoutForm