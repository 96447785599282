import MarkCastro from 'assets/images/dummy/mark_castro.png'
import AndrewVernon from 'assets/images/dummy/andrew_vernon.png'
import MarzelleQuinones from 'assets/images/dummy/marzelle_quinones.png'
import SunainaChaud from 'assets/images/dummy/sunaina_chaud.png'

import Kitchenware from 'assets/images/dummy/kitchenware.png'
import Mango from 'assets/images/dummy/mango.png'
import Fb from 'assets/images/dummy/fb.png'
import Kidsart from 'assets/images/dummy/kidsart.png'
import Cale from 'assets/images/dummy/cale.png'

export const dummyJobseekers = [
  {
    avatar: MarkCastro,
    name: 'Mark Castro',
    job_title: 'Virtual assistant',
    skills: ['Customer Support', 'Customer Service'],
    location: 'Canada',
    num_experience: '3',
    amount: '10',
    type: 'hour',
    bio: 'As a social media expert. I give brands a voice through compelling content, specializing in social media, websites, and online platforms. Proficient in managing accounts from engagement to analytics.',
  },
  {
    avatar: AndrewVernon,
    name: 'Andrew Vernon',
    job_title: 'Cold Caller',
    skills: ['Customer Support', 'Cold calling', 'Emphathy'],
    location: 'Australia',
    num_experience: '3',
    amount: '20',
    type: 'annual',
    bio: 'I am an Inbound/Outbound Sales Specialist with 6 years in the Business Process Outsource industry. Meticulous in administrative support, skilled in agenda preparation, meeting scheduling, and action item follow-up.',
  },
  {
    avatar: MarzelleQuinones,
    name: 'Marzelle Quinones',
    job_title: 'UX Designer',
    skills: [
      'User Persona Creation',
      'Creative Thinking',
      'Wireframing',
      'Prototyping',
      'Illustration',
      'Photoshop',
      'Figma',
      'UI/UX',
    ],
    location: 'Philippines',
    num_experience: '3',
    amount: '10',
    type: 'hour',
    bio: 'Motion Graphic Designer/Video Editor, with a passion for crafting exceptional digital experiences. With a background in UI/UX design and a keen eye for detail, I thrive on turning complex problems into intuitive and visually appealing solutions.',
  },
  {
    avatar: SunainaChaud,
    name: 'Sunaina Chaudhary',
    job_title: 'Full Stack Developer',
    skills: ['ReactJS', 'NodeJS', 'AWS'],
    location: 'India',
    num_experience: '8',
    amount: '10',
    type: 'hour',
    bio: 'Full Stack Developer experienced with AWS, NodeJS, GraphQL, TypeScript, ReactJS, Redux, Jest, and Salesforce. I have over 8 years of developer experience solving complex problems in a timely manner. I have worked in text tile, healthcare, insurance, E-commerce, Edtech, Fintech, Travel and hospitality and Non-Profit sector industry ',
  },
]

export const dummyJobPosts = [
  {
    avatar: Kitchenware,
    company_name: 'Kitchenware Center',
    duration: 'Full Time',
    location: 'Anywhere',
    job_role: 'Appointment Setter',
    skills: ['Sales', 'Sales Management', '+12'],
    qualifications: [
      'Utilize social media for client identification and engagement.',
      'Build relationships through compelling communication.',
      'Schedule sales appointments with qualified prospects.',
      'Stay updated on industry trends for outreach strategy.',
      'Collaborate with marketing to optimize lead generation content.',
    ],
    requirements: [
      'Self-motivated, adept at independent work.',
      'Familiar with scheduling tools and CRM software.',
      'Passionate about building genuine connections.',
    ],
  },
  {
    avatar: Mango,
    company_name: 'Mango Group',
    duration: 'Part-time',
    location: 'Remote',
    job_role: 'Senior Front End Engineer',
    skills: ['React.js', 'Web Design', 'Node.js', '+12'],
    qualifications: [
      'Extensive experience in Javascript (ReactJS).',
      'Adept at building performant, decoupled, testable code.',
      'Passion for open source, quick learner of new technologies.',
      'Proficient in plain JavaScript/TypeScript, leveraging libraries intelligently.',
      'Strong in React and ES6.',
      'Some exposure to cloud platforms (AWS, GAE, Azure).',
      'Knowledgeable in web app, SOA, and microservices patterns.',
    ],
    requirements: [],
  },
  {
    avatar: Fb,
    company_name: 'F&B Retail',
    duration: 'Part-time',
    location: 'Anywhere',
    job_role: 'Customer Service Representative',
    skills: [
      'Customer Service',
      'Customer Satisfaction',
      'Sales Management',
      '+12',
    ],
    qualifications: [
      'Customer service-oriented individual.',
      'Excellent interpersonal and communication skills.',
      'Schedule Flexibility.',
      'Advanced proficiency in both written and spoken English.',
      'No prior experience required. We provide paid training.',
    ],
    requirements: [
      'Internet speed of 25 Mbps.',
      'Need a quiet, noise-free workspace.',
      'Preferably available to work according to PT Time.',
    ],
  },
  {
    avatar: Kidsart,
    company_name: 'Kids for Art',
    duration: 'Part-time',
    location: 'Remote',
    job_role: 'Social Media Associate (Project-based)',
    skills: ['Social Media', 'Social Media Marketing', '+12'],
    qualifications: [
      'Familiarity with major social media platforms (Facebook, Twitter, Instagram, LinkedIn) ',
      'Proficiency in using management tools for scheduling, tracking engagement, and analyzing data is essential.',
      'Content creation experience essential; portfolio required.',
      'Graphic design skills are a plus for creating social media graphics and images but not required.',
      'Experience with search engine optimization (SEO) is a plus.',
    ],
    requirements: [],
  },
  {
    avatar: Cale,
    company_name: 'Cale Holdings',
    duration: 'Part-time',
    location: 'Remote',
    job_role: 'Real Estate Inside Sales',
    skills: ['Sales', 'Sales Management', '+12'],
    qualifications: [
      'Excellent communication skills, listening skills, presentation skills, and customer service skills.',
      'Understands the sales process and how to use CRM software.',
      'At least one year of experience as a real estate sales representative.',
    ],
    requirements: [
      'Utilize CRM for sales data.',
      'Follow up with customers for repeat business.',
      'Create sales reports to track progress.',
      'Manage pre-qualified leads.',
    ],
  },
]
