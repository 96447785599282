import Avatar from 'components/Avatar'
import React, { useState, useEffect } from 'react'
import { useSelector } from 'react-redux'
import { getUser, getProfile } from 'app/store/profileSlice'
import { send } from 'react-ga4'
import { useNavigate } from 'react-router-dom'

export default function NameRenderer({ sender }) {
  const navigate = useNavigate()
  const profile = useSelector(getUser)
  const [name, setName] = useState('')
  // const rendererJSON = JSON.parse(sender.custom_json)

  useEffect(async () => {
    await getProfile(sender.username).then((response) => {
      if (response.data.user.emType === 'company') {
        setName(response.data.user.companyName)
      } else if (response.data.user.emType === 'individual') {
        let nameIndi =
          response.data.user.firstName + ' ' + response.data.user.lastName
        setName(nameIndi)
      } else {
        setName(response.data.user.name)
      }
    })
  }, [sender])

  const onClickProfile = () => {
    if (profile.id !== sender.username) {
      if (profile.type === 'contractor') {
        navigate(`/contractor/profile/${sender.username}`)
      } else {
        navigate(`/employer/profile/${sender.username}`)
      }
    }
  }

  return (
    <div
      onClick={onClickProfile}
      className={`text-black-100 leading-5 text-sm font-semibold ${
        profile.id !== sender.username && 'cursor-pointer'
      }`}
    >
      {name}
    </div>
  )
}
