//packages
import React, { useEffect, useState, useCallback } from 'react'
import { useParams, useNavigate } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { getOrCreateChat } from 'react-chat-engine'
import { createUser } from 'app/services/chat.service'

//custom components
import Avatar from 'components/Avatar'
import Button from 'components/Button'
import { Tab } from 'components/Tabs'
import AboutSection from 'views/contractor/profile/components/AboutSection'
import PortfolioSection from 'views/contractor/profile/components/PortfolioSection'
import NewJobPostingModal from '../your-job-posts/components/NewJobPostingModal'
import InviteToJobModal from '../candidates/components/InviteToJobModal'
import NoJobPostModal from '../candidates/components/NoJobPostModal'

//redux & apis
import { getProfile } from 'app/store/profileSlice'
import { ChatIcon2, MessageCandidateIcon, CirclePlusIcon, BackCircleIcon } from 'components/Icons'
import ChatNoPermissionModal from 'components/Dialog/ChatNoPermissionModal'
import { getUser } from 'app/store/profileSlice'
import { getChatsNo } from 'app/store/profileSlice'
import { fetchChatsNo } from 'app/store/profileSlice'
import { getJobsByUser } from 'app/store/jobSlice'

//assets
const TABS = ['About', 'Portfolio']

export default function CandidateProfile() {
  const [user, setUser] = useState(null)
  const [activeTab, setActiveTab] = useState('About')
  const [openChatNoPermissionModal, setOpenChatNoPermissionModal] =
    useState(false)
  const [showInviteModal, setShowInviteModal] = useState(false)
  const [openNewJobPostingModal, setOpenNewJobPostingModal] = useState(false)
  const [openNoJobPostModal, setOpenNoJobPostModal] = useState(false)
  const [jobForInvite, setJobForInvite] = useState(null)
  const [userIdForInvite, setUserIdForInvite] = useState('')

  const navigate = useNavigate()
  const dispatch = useDispatch()
  const { id } = useParams()
  const profile = useSelector(getUser)
  const userId = useSelector((state) => state.auth.userId)
  const jobs = useSelector((state) => state.job.jobs)
  const chatLimits = useSelector(getChatsNo)

  useEffect(() => {
    const fetchUser = async () => {
      const result = await getProfile(id)
      setUser(result.data.user)
    }
    fetchUser()
  }, [id])

  // for fetching jobs when reloads this page
  const fetchJobsList = useCallback(async () => {
    if (userId && jobs.length === 0) {
      dispatch(getJobsByUser(userId))
    }
  }, [userId, jobs])

  useEffect(() => {
    fetchJobsList()
  }, [fetchJobsList])

  useEffect(() => {
    dispatch(getJobsByUser(userId))
  }, [])

  const handleChat = async() => {
    {/* PREMIUM start comment this out no premium user */}
    // if (profile.plan === 'basic' && chatLimits >= 5) {
    //   setOpenChatNoPermissionModal(true)
    // } else {
    //   const creds = {
    //     projectID: process.env.REACT_APP_CHAT_ENGINE_PROJECT_ID,
    //     userName: profile.id,
    //     userSecret: profile.id,
    //   }
    //   getOrCreateChat(
    //     creds,
    //     {
    //       is_direct_chat: true,
    //       usernames: [user.id],
    //       title: `Direct Message with ${user.name}`,
    //     },
    //     (data) => {
    //       const param = {
    //         userName: profile.id,
    //         userSecret: profile.id,
    //       }
    //       dispatch(fetchChatsNo(param))
    //       navigate(`/employer/messages?chatId=${data.id}`)
    //     }
    //   )
    // }
    {/* end comment this out no premium user */}

    const creds = {
      projectID: process.env.REACT_APP_CHAT_ENGINE_PROJECT_ID,
      userName: profile.id,
      userSecret: profile.id,
    }

    try {
      const params = {
        username: user.id,
        secret: user.id,
        first_name: user.type,
        last_name: user.name,
        custom_json: JSON.stringify({ image: user.avatar }),
      }
      await createUser(params).then(() => {
        getOrCreateChat(
          creds,
          {
            is_direct_chat: true,
            usernames: [user.id],
            title: `Direct Message with ${user.name}`,
          },
          (data) => {
            const param = {
              userName: profile.id,
              userSecret: profile.id,
            }
            dispatch(fetchChatsNo(param))
            navigate(`/employer/messages?chatId=${data.id}`)
          }
        )
      })
      console.log("chatUser", chatUser)
    } catch (err) {
      // console.log(err)
      // means username is already taken
      console.log("taken")
      getOrCreateChat(
        creds,
        {
          is_direct_chat: true,
          usernames: [user.id],
          title: `Direct Message with ${user.name}`,
        },
        (data) => {
          const param = {
            userName: profile.id,
            userSecret: profile.id,
          }
          dispatch(fetchChatsNo(param))
          navigate(`/employer/messages?chatId=${data.id}`)
        }
      )
    }
  }

  const upgradeNow = () => {
    navigate('/employer/upgrade')
  }

  const openInviteModal = (param) => {
    if (jobs.length === 0) {
      // setOpenNewJobPostingModal(true)
      setOpenNoJobPostModal(true)
    } else {
      setJobForInvite(null)
      setUserIdForInvite(user.id)
      setShowInviteModal(true)
    }
  }

  const goBack = () => {
    navigate(-1)
  }

  if (!user) return null

  return (
    <div className="md:flex min-h-screen px-[15px] md:px-16">
      <div className="py-[37px] md:py-12 flex-1 relative mb-15 md:mb-0">
        <h5
          onClick={goBack}
          className="leading-[21px] md:leading-8 font-medium text-[14px] md:text-2xl text-black-100 flex space-x-[18px] items-center cursor-pointer"
        >
          <BackCircleIcon />
          <h5 className="text-[#52B4DA] text-[24px] font-medium">Back</h5>
        </h5>
        <div className="bg-white rounded-3xl pl-[20px] md:pl-12 pr-[20px] md:pr-7 py-[20px] md:py-5 relative mt-5 md:mt-8">
          <div className="flex justify-between items-center">
            <div className="flex items-center space-x-[25px] md:space-x-8">
              <div className="w-[80px] md:w-28 h-[80px] md:h-28 flex justify-center items-center border-[4px] border-[#0049C6] rounded-full border-solid">
                <Avatar
                  image={user.avatar}
                  className="w-[68px] md:w-20 h-[68px] md:h-20"
                />
              </div>
              <div>
                <h3 className="text-black-100 text-[18px] md:text-5xl leading-[27px] md:leading-18 font-semibold">
                  {user.name}
                </h3>
                <div className="flex md:hidden items-center space-x-6 mt-[15px]">
                  <div className="flex items-center space-x-[15px]">
                    <Button
                      variant="secondary"
                      className="px-[13px] md:px-8 py-[6px] md:py-4.5 flex items-center space-x-[7px] text-[12px] rounded-[5px]"
                      onClick={openInviteModal}
                    >
                      <CirclePlusIcon
                        size={13}
                        className="mr-[7px] text-green-100"
                      />
                      Invite
                    </Button>
                  </div>
                  <div onClick={handleChat}>
                    <ChatIcon2
                      size={18}
                      className="text-green-100 cursor-pointer"
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className="hidden md:flex items-center space-x-6">
              <div onClick={handleChat} className="cursor-pointer">
                <MessageCandidateIcon />
              </div>
              <div className="flex items-center space-x-5">
                <Button
                  variant="secondary"
                  className="px-8 py-4.5"
                  onClick={openInviteModal}
                >
                  Invite to Job
                </Button>
              </div>
            </div>
          </div>
        </div>
        {/* Tabs Section */}
        <div className="mt-8">
          <Tab
            list={TABS}
            activeTab={activeTab}
            onChange={() =>
              setActiveTab((prev) => (prev === 'About' ? 'Portfolio' : 'About'))
            }
          />
        </div>

        <div className="mt-6">
          {activeTab === 'About' && <AboutSection profile={user} />}
          {activeTab === 'Portfolio' && (
            <PortfolioSection profile={user} enableAddNew={false} />
          )}
        </div>
      </div>
      <ChatNoPermissionModal
        isOpen={openChatNoPermissionModal}
        closeModal={() => setOpenChatNoPermissionModal(false)}
        upgradeNow={upgradeNow}
      />
      <NoJobPostModal
        isOpen={openNoJobPostModal}
        closeModal={() => {
          setOpenNoJobPostModal(false)
        }}
        openNewJobPost={() => {
          setOpenNewJobPostingModal(true)
          setOpenNoJobPostModal(false)
        }}
      />
      <NewJobPostingModal
        isOpen={openNewJobPostingModal}
        closeModal={() => setOpenNewJobPostingModal(false)}
      />
      <InviteToJobModal
        isOpen={showInviteModal}
        jobForInvite={jobForInvite}
        setJobForInvite={setJobForInvite}
        closeModal={() => {
          setShowInviteModal(false)
          setUserIdForInvite('')
        }}
        userIdForInvite={userIdForInvite}
        setUserIdForInvite={setUserIdForInvite}
      />
    </div>
  )
}
