import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { toast } from 'react-toastify'

import { resetAllStatus } from 'app/store/applicantsSlice'
import Avatar from 'components/Avatar'
import Button from 'components/Button'
import clsx from 'clsx'

export default function DetailPanel({
  job,
  creator,
  handleWithdrawModal,
  handleRejectModal,
  handleAcceptModal,
  handleUnRejectModal,
  status,
  waitlisted,
  type,
  loading,
}) {
  const navigate = useNavigate()

  const handleViewJob = () => {
    navigate(`/contractor/jobs/${job.id}`)
  }

  const isProcessed = status === 'rejected' || status === 'accepted'

  if (!job) return null

  return (
    <div className="w-full bg-white rounded-3xl flex md:space-x-6 py-[20px] md:py-9 px-[25px] md:px-8">
      <div className="hidden md:block basis-[48px]">
        <div className="w-[48px] h-[48px] rounded-full overflow-hidden">
          <Avatar image={creator?.logo} className="" />
        </div>
      </div>
      <div className="flex-1">
        <div className="flex items-center text-sm text-gray-1200 leading-4">
          <div className="text-blue-100 font-semibold hidden md:block mr-[10px]">
            {creator?.emType === 'individual'
              ? `${creator?.firstName} ${creator?.lastName}`
              : creator?.companyName}
          </div>
          <div className="w-[3px] h-[3px] bg-[#73747A] hidden md:block mr-[10px]" />
          <div className="uppercase text-green-100 font-bold mr-[10px]">
            {creator?.pay_preference === 'hourly' ? 'Hourly' : 'Annual'}
          </div>
          <div className="w-[3px] h-[3px] bg-[#73747A] mr-[10px]" />
          <div className="font-semibold mr-[10px]">Full Time</div>
          <div className="w-[3px] h-[3px] bg-[#73747A] mr-[10px]" />
          <div className="font-semibold mr-[10px]">English</div>
        </div>
        <div className="text-[16px] md:text-lg font-semibold leading-[22px] mt-4">
          {job.title}
        </div>
        <div className="font-inter text-gray-100 leading-6 text-[14px] md:text-sm mt-[10px] md:mt-0">
          <div
            class="content"
            dangerouslySetInnerHTML={{ __html: job.description }}
          />
        </div>
        {type === 'applicant' ? (
          <div className="md:flex justify-center md:space-x-[19px] space-x-0 md:space-y-0 space-y-5 mt-[30px]">
            <div className="md:inline-block block md:w-auto w-full">
              <Button
                onClick={handleViewJob}
                variant="primary"
                className="flex-1 p-14 md:w-auto w-full"
              >
                View Job
              </Button>
            </div>
            {status === 'pending' && !waitlisted && (
              <Button
                variant="gray"
                onClick={() => handleWithdrawModal(job.id)}
                disabled={loading}
                className="bg-[#808191] p-6 md:w-auto w-full"
              >
                Withdraw{' '}
                <span className="ml-[6px] hidden md:block">Application</span>
              </Button>
            )}
          </div>
        ) : (
          <div
            className={clsx(
              `md:space-x-[19px] mt-[30px] md:flex space-x-0 md:space-y-0 space-y-5 ${
                status === 'accepted' ? 'justify-center' : ''
              }`
            )}
          >
            <div className="md:inline-block block md:w-auto w-full">
              <Button
                onClick={handleViewJob}
                variant="primary"
                className="flex-1 p-14 md:w-auto w-full"
              >
                View Job
              </Button>
            </div>
            {!isProcessed && !waitlisted && (
              <Button
                variant="secondary"
                className="ml-[11px] md:ml-0 flex-1 md:w-auto w-full"
                onClick={handleAcceptModal}
              >
                Accept Invite & Apply
              </Button>
            )}
            {!isProcessed && !waitlisted && (
              <Button
                className="flex-1 mt-[10px] md:mt-0 md:w-auto w-full"
                variant="error"
                onClick={handleRejectModal}
              >
                Reject Invitation
              </Button>
            )}
            {/* {status === 'rejected' && (
              <Button
                className="flex-1 mt-[10px] md:mt-0"
                variant="error"
                onClick={handleUnRejectModal}
              >
                Un-reject
              </Button>
            )} */}
          </div>
        )}
      </div>
    </div>
  )
}
