//packages
import React from 'react'
import { useNavigate } from 'react-router-dom'

//custom components
import { kFormatter } from 'app/utils/function'
import DefaultAvatar from 'assets/images/default-avatar.png'

// const skills = ['React', 'Node', 'Express', 'React Native', 'Python', 'Ruby']
// const skills = ['Photoshop', 'Figma', 'Illustration']

export default function ({ data }) {
  const navigate = useNavigate()
  const onViewProfile = () => {
    navigate('/auth/register')
  }
  return (
    <div className="md:w-80 shadow-[4px_4px_16px_rgba(49,89,211,0.06)] rounded-3xl p-8">
      <div className="flex space-x-4">
        <div className="w-[80px] h-[107px] shadow-[0px_4px_8px_rgba(225,227,242,0.32)]">
          <img
            className="w-full h-full rounded-lg object-top"
            src={data.avatar ? data.avatar : DefaultAvatar}
          />
        </div>
        <div>
          <p className="text-black-100 font-medium text-[14px] leading-6">
            {data.name}
          </p>
          <div className="flex items-center justify-between gap-2">
            <p className="text-blue-100 text-[11px] font-medium leading-6">
              {data.job_title}
            </p>
            <div className="w-[2px] h-[2px] bg-gray-100" />
            <p className="text-gray-1200 text-[11px] font-medium leading-6">
              {data.location}
            </p>
          </div>
          <p className="text-blue-300 font-bold text-[11px] leading-6 uppercase">
            {data.num_experience} years Exp.
          </p>
          <p className="text-blue-300 font-bold text-[16px] mt-3">
            ${data.amount}
            <span className="font-medium text-[12px] text-gray-100">
              / {data.type}
            </span>
          </p>
        </div>
      </div>
      {/* <div className="flex items-center space-x-3 mt-6">
        <p className="text-green-100 text-uppercase leading-4 font-semibold text-xs">
          {data.annual_rate
            ? `$${kFormatter(data.annual_rate)}/year`
            : `$${data.hourly_rate}/hr`}{' '}
        </p>
        <div className="w-[2px] h-[2px] bg-gray-100" />
        <p className="text-gray-100 text-xs leading-4 font-medium">English</p>
        <div className="w-[2px] h-[2px] bg-gray-100" />
        <p className="text-gray-100 text-xs leading-4 font-medium">
          Tokyo, Japan
        </p>
      </div> */}
      <div className="flex flex-wrap max-h-[112px] overflow-hidden">
        {data.skills.slice(0, 4).map((skill) => (
          <div className="mr-2 rounded-lg text-[12px] leading-5 text-gray-100 px-2 py-2 mt-4 border border-[#E0E5F9]">
            {skill}
          </div>
        ))}
        {data.skills.length > 4 && (
          <div className="mr-2 rounded-lg text-xs leading-5 text-purple-100 px-3 py-2.5 mt-4">
            +{data.skills.length - 4}
          </div>
        )}
      </div>
      <div className="text-[13px] leading-6 text-gray-100 mt-2">
        <div
          class="content"
          dangerouslySetInnerHTML={{
            __html: data.bio,
          }}
        />
      </div>
      <div className="mt-10">
        <button
          onClick={onViewProfile}
          className="text-blue-100 text-sm leading-5 font-semibold py-3 px-6 border-[1.5px] border-[rgba(83,180,218,0.7)] rounded-xl btn-hover"
        >
          View Profile
        </button>
      </div>
    </div>
  )
}
