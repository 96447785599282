//packages
import React, { useCallback, useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import { useNavigate } from 'react-router-dom'

import { ArrowRight } from 'components/Icons'
import Footer from './components/Footer'
import Header from './components/Header'

import { ThinkSearchIcon } from 'components/Icons'
import useTagList from 'hooks/useTagList'

import 'react-multi-carousel/lib/styles.css'

import RecentJobPostings from './components/RecentJobPostings'
import BlogPosting from './components/BlogPosting'
import TopContractorSection from './components/TopContractorSection'

//assets
import ActiveIcon from 'assets/images/active-icon.png'
import InActiveIcon from 'assets/images/inactive-icon-free-card.png'
import PremiumRRLogo from 'assets/images/premium-icon-card.png'
import {
  fetchCandidates as fetchCandidatesSlice,
  getCandidates,
} from 'app/store/candidateSlice'
import { searchJobsByFilter } from 'app/store/jobSlice'
import { useSelector } from 'react-redux'
import RRLogo from 'assets/images/free-card-rr-logo.png'
import IntroductoryVideo from './components/IntroductoryVideo'
import { dummyJobseekers, dummyJobPosts } from './dummy'
import { dummyBlogs } from './dummyBlog'
import authService from 'app/services/auth.service'

export default function Home() {
  const [skills] = useTagList()
  const [searchKey, setSearchKey] = useState('')

  useEffect(() => {
    authService
      .autoLogin()
      .then((token) => {
        if (token) {
          console.log('User is logged in')
          // Set the token in Axios default headers or do any additional setup
          const user = JSON.parse(localStorage.getItem('user'))
          navigate(`/${user.type}/profile`)
        } else {
          console.log('User is not logged in')
          // Redirect to login page or show login modal
        }
      })
      .catch((error) => {
        console.error('Auto login error:', error)
      })
  })

  // const candidates = useSelector(getCandidates)
  const candidates = dummyJobseekers
  // const jobs = useSelector((state) => state.job.jobs)
  const jobs = dummyJobPosts
  const dispatch = useDispatch()
  const navigate = useNavigate()

  const fetchCandidates = useCallback(() => {
    let param = {
      offset: 0,
      limit: 10,
    }
    dispatch(fetchCandidatesSlice(param))
  }, [dispatch])

  useEffect(() => {
    fetchCandidates()
  }, [fetchCandidates])

  const fetchJobsList = useCallback(() => {
    let param = {
      offset: 0,
      limit: 10,
    }
    dispatch(searchJobsByFilter(param))
  }, [])

  useEffect(() => {
    fetchJobsList()
  }, [fetchJobsList])

  const gotoRegisterPage = () => {
    navigate('/auth/register')
  }

  const handleToGetStarted = () => {
    navigate('/auth/register')
  }

  return (
    <div className="relative">
      <div className="absolute top-0 z-[-1] w-full">
        <picture>
          <source
            srcSet="/img/home-bg/Main_Background_eide2s_c_scale,w_375.png"
            media="(max-width: 600px)"
          />
          <source
            srcSet="/img/home-bg/Main_Background_eide2s_c_scale,w_1440.png"
            media="(min-width: 600px)"
          />
          <img
            className="w-full md:max-h-[1000px]"
            src="/img/home-bg/Main_Background_eide2s_c_scale,w_1440.png"
          />
        </picture>
      </div>
      <Header active="Home" />
      <div className="hidden md:flex justify-end relative w-full">
        <img
          className="absolute h-[176px] w-[120px] right-[0px] z-20 top-[-18px]"
          src="/img/home-intro/diamond_circle.png"
        />
        <img
          className="absolute right-[0px] w-full sm:max-w-[500px] lg:max-w-[550px] xl:max-w-[650px] 2xl:max-w-[800px] 3xl:max-w-[1000px] top-[-30px]"
          src="/img/home-intro/Device Mock Up@2x.png"
        />
        <img
          className="absolute right-96 xl:mr-10 2xl:mr-20 3xl:mr-40 w-full sm:max-w-[200px] lg:max-w-[250px] xl:max-w-[300px] 2xl:max-w-[350px] 3xl:max-w-[550px] top-96 sm:-mt-10 lg:-mt-5 xl:mt-0 2xl:mt-24 3xl:mt-40"
          src="/img/home-intro/Job Card.png"
        />
      </div>
      <div className="mx-auto max-w-5xl">
        <div className="flex flex-col md:block relative">
          <div className="mt-[35px] md:hidden sm:mt-0 order-last md:order-1 flex flex-end w-full sm:absolute right-0 sm:w-[300px] lg:w-[600px] xl:w-[800px]">
            <img
              sizes="(max-width: 900px) 100vw, 900px"
              srcSet="/img/home-intro/home-intro_wcsnmm_c_scale,w_200.png 200w,
              /img/home-intro/home-intro_wcsnmm_c_scale,w_520.png 520w,
              /img/home-intro/home-intro_wcsnmm_c_scale,w_748.png 748w,
              /img/home-intro/home-intro_wcsnmm_c_scale,w_917.png 917w"
              src="/img/home-intro/home-intro_wcsnmm_c_scale,w_917.png"
              alt=""
            />
          </div>
          <section className="">
            <div className="max-w-xl pt-[53px] md:pt-20 xl:pt-32 px-[15px] md:px-0">
              <h2 className="text-white text-[24px] md:text-5xl font-semibold leading-[36px] md:leading-18">
                Find Talent, Anywhere in the World.
              </h2>
              <h5 className="text-white opacity-80 font-medium text-[14px] md:text-lg leading-[24px] md:leading-7 mt-[12px] md:mt-8 max-w-[500px]">
                RemoteRecruit is helping connect employers with job seekers all
                over the world. RemoteRecruit is incredibly easy to use without
                the endless fees and charges on other recruitment sites.
              </h5>
              <button className="inline-flex items-center space-x-3 bg-white bg-opacity-20 pr-5 pl-2 py-2 rounded-[32px] mt-12 cursor-pointer">
                <div className="w-[36px] h-[36px] md:w-[46px] md:h-[46px]">
                  <ArrowRight size={46} className="w-full h-full" />
                </div>
                <div
                  onClick={handleToGetStarted}
                  className="text-white text-[14px] md:text-lg font-medium leading-6"
                >
                  Get Started
                </div>
              </button>
            </div>
          </section>
        </div>
        <div className="xl:mt-7 2xl:mt-24 3xl:mt-80 4xl:mt-96">
          <IntroductoryVideo videoSource="https://remote-recruit-website-assets.s3.amazonaws.com/rr_optimised_video.mp4" />
        </div>
        <div className="xl:mt-5 2xl:mt-5 3xl:mt-5">
          <TopContractorSection candidates={candidates} />
        </div>

        <div className="xl:mt-5 2xl:mt-5 3xl:mt-5">
          <RecentJobPostings jobs={jobs} />
        </div>

        <BlogPosting blogs={dummyBlogs} />

        {/* comment breakdown on homepage */}
        {/* <section className="pt-[50px] md:pt-36">
          <h2 className="text-black-100 font-semibold leading-[30px] md:leading-18 text-[20px] md:text-5xl text-center">
            Help Is One Click Away
          </h2>
          <div className="mx-[15px] md:mx-0 grid grid-cols-1 md:grid-cols-2 gap-y-[20px] md:gap-y-0 md:gap-x-10 z-10 relative mt-12">
            <div className="bg-white rounded-3xl p-[10px] pb-[27px] md:p-8 shadow-[-15px_50px_150px_rgba(49,89,211,0.12)]">
              <div className="md:flex md:space-x-8 space-y-[17px] md:space-y-0">
                <div className="py-[18px] px-[20px] w-full md:w-40 md:h-44 flex-none bg-[#ECF2FF] rounded-2xl grid grid-cols-2 md:flex md:flex-col md:justify-center">
                  <div className="block md:hidden flex-none">
                    <img
                      src={RRLogo}
                      className="w-[71px] h-[71px] rounded-full"
                    />
                  </div>
                  <div className="flex flex-col justify-center">
                    <h6 className="text-[#52B4DA] leading-11 text-m3xl font-semibold md:text-center">
                      Free
                    </h6>
                    <div className="leading-9 text-xl md:text-center font-medium text-[rgba(17,20,45,0.39554)]">
                      Basic
                    </div>
                  </div>
                </div>
                <div>
                  <ul className="space-y-5">
                    <li className="flex items-start font-poppins font-medium leading-[24px] text-[14px] md:text-[16px]">
                      <img
                        src={ActiveIcon}
                        className="w-5 h-5 mr-3 text-[#323445] text-base leading-6 font-medium mt-1"
                      />
                      1 Active Job
                    </li>
                    <li className="flex items-start font-poppins font-medium leading-[24px] text-[14px] md:text-[16px]">
                      <img
                        src={ActiveIcon}
                        className="w-5 h-5 mr-3 text-[#323445] text-base leading-6 font-medium mt-1"
                      />
                      Basic List Placement
                    </li>
                    <li className="flex items-start font-poppins font-medium leading-[24px] text-[14px] md:text-[16px]">
                      <img
                        src={InActiveIcon}
                        className="w-5 h-5 mr-3 text-[#808191] text-base leading-6 font-medium mt-1"
                      />
                      Unlimited Job Applicants
                    </li>
                    <li className="flex items-start font-poppins font-medium leading-[24px] text-[14px] md:text-[16px]">
                      <img
                        src={InActiveIcon}
                        className="w-5 h-5 mr-3 text-[#808191] text-base leading-6 font-medium mt-1"
                      />
                      Invite Anyone to Apply to Your Jobs
                    </li>
                  </ul>
                </div>
              </div>
              <button
                onClick={gotoRegisterPage}
                className="mt-7 bg-white border-[2px] border-[#1E3E85] rounded-3xl w-full py-6 leading-6 text-xl font-semibold text-blue-100"
              >
                Get Started
              </button>
            </div>
            <div className="grid bg-white rounded-3xl p-[10px] pb-[27px] md:p-8 shadow-[-15px_50px_150px_rgba(49,89,211,0.12)]">
              <div className="md:hidden flex w-44 sm:h-38 space-x-4 items-center pr-4 p-5 mb-5 bg-green-300 rounded-[22px] shadow-[rgba(0,4,4,0.2)]">
                <div className="w-8 h-8">
                  <img
                    src={PremiumRRLogo}
                    className="w-full h-full rounded-full"
                  />
                </div>
                <div className="text-black-100 leading-4 text-base font-semibold">
                  Premium
                </div>
              </div>
              <div className="md:flex md:space-x-8 px-[5px] md:px-0 space-y-[17px] md:space-y-0">
                <div className="relative w-full md:w-40 flex-none md:h-44 bg-[#ECF2FF] rounded-2xl flex flex-col justify-center">
                  <div className="hidden md:flex w-fit md:w-[calc(100%-10px)] -top-5.5 left-[5px] md:absolute space-x-3 items-center pr-4 p-1 bg-green-300 rounded-[22px] shadow-[rgba(0px_4px_4px_rgba(67,145,193,0.2))]">
                    <div className="w-9 h-9">
                      <img
                        src={PremiumRRLogo}
                        className="w-full h-full rounded-full"
                      />
                    </div>
                    <div className="text-black-100 leading-4 text-base font-semibold">
                      Premium
                    </div>
                  </div>
                  <div className="grid grid-cols-2 md:grid-cols-1 py-[18px] px-[20px]">
                    <div className="w-[71px] h-[71px] block md:hidden">
                      <img
                        src={PremiumRRLogo}
                        className="w-full h-full rounded-full"
                      />
                    </div>
                    <div className="flex flex-col justify-center">
                      <h6 className="text-transparent bg-clip-text bg-gradient-to-r from-[#52B4DA] to-[#1E3E85] leading-11 text-m3xl font-semibold md:text-center">
                        $79.99
                      </h6>
                      <div className="leading-9 text-xl md:text-center font-medium text-[rgba(17,20,45,0.39554)]">
                        Per Month
                      </div>
                    </div>
                  </div>
                </div>
                <div>
                  <ul className="space-y-5">
                    <li className="flex items-start font-poppins font-medium leading-[24px] text-[14px] md:text-[16px]">
                      <img
                        src={ActiveIcon}
                        className="w-5 h-5 mr-3 text-[#323445] text-base leading-6 font-medium"
                      />
                      Unlimited Job Posts
                    </li>
                    <li className="flex items-start font-poppins font-medium leading-[24px] text-[14px] md:text-[16px]">
                      <img
                        src={ActiveIcon}
                        className="w-5 h-5 mr-3 text-[#323445] text-base leading-6 font-medium"
                      />
                      Instant Job Post Approval
                    </li>
                    <li className="flex items-start font-poppins font-medium leading-[24px] text-[14px] md:text-[16px]">
                      <img
                        src={ActiveIcon}
                        className="w-5 h-5 mr-3 text-[#323445] text-base leading-6 font-medium"
                      />
                      Premium List Placement
                    </li>
                    <li className="flex items-start font-poppins font-medium leading-[24px] text-[14px] md:text-[16px]">
                      <img
                        src={ActiveIcon}
                        className="mt-[2px] w-5 h-5 mr-3 text-[#323445] text-base leading-6 font-medium"
                      />
                      Unlimited Job Applicants
                    </li>
                  </ul>
                </div>
              </div>
              <button
                onClick={gotoRegisterPage}
                className="self-end text-white mt-7 border-[2px] border-white premium-card-btn-bg bg-white rounded-3xl w-full py-6 leading-6 text-xl font-semibold"
              >
                Get Started
              </button>
            </div>
          </div>
        </section> */}
      </div>
      <div className="mt-24">
        <Footer className="z-0 pt-[160px] sm:pt-[200px] xl:pt-64 2xl:pt-80" />
      </div>
    </div>
  )
}
