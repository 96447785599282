//packages
import React, { useState } from 'react'
import { Link, Outlet, useNavigate, useLocation } from 'react-router-dom'


//custom components
import { CrossIcon } from 'components/Icons'
import NotificationExitModal from './NotificationExitModal'

//assets
import HomeLogo from 'assets/img/logo-home.png'

export default function AuthLayout() {
  const [showModal, setShowModal] = useState(false);
  const navigate = useNavigate()
  const location = useLocation()
  const { role } = location.state ? location.state : "";
  const pathName = location.pathname ? location.pathname : "";

  const back = () => {
    let show_signup_notification = localStorage.getItem("show_signup_notification");
    if (show_signup_notification !== null) {
      if (show_signup_notification === "true") {
        setShowModal(true);
      } else {
        navigate('/')
      }
    } else {
      navigate('/')
    }
  }


  return (
    <div className="min-h-screen w-full mobile-auth-bg md:bg-auth-bg bg-no-repeat bg-top bg-[length:100%_48vh] relative overflow-y-auto">
      <Link
        to="/"
        className="absolute top-[40px] md:top-10 left-[15px] md:left-10"
      >
        <img className="w-[82px] md:w-27.5" src={HomeLogo} alt="home-logo" />
      </Link>

      {/* jobseeker/contractor should not view this X icon at top right */}
      {pathName !== "/auth/contractor/register" && role !== "contractor" && (
        <div
          className="w-[24px] md:w-12 h-[24px] md:h-12 flex justify-center items-center bg-white absolute rounded-full top-[45px] md:top-12 right-[15px] md:right-12 cursor-pointer hover:opacity-80"
          onClick={back}
        >
          <CrossIcon className="w-[12px] md:w-[24px] h-[12px] md:h-[24px]" />
        </div>
      )}
      
      <div className="flex w-full min-h-screen justify-center items-center overflow-y-auto">
        <Outlet />
      </div>


      {showModal && (
        <NotificationExitModal
          isOpen={showModal}
          closeModal={() => setShowModal(false)}
          callback={() => navigate('/')}
        />
      )}
    </div>
  )
}
